export const PoStatusEnum = {
  OPEN: "OPEN",
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED"
}

export const PoStatusList = [
  "OPEN",
  "PENDING",
  "COMPLETED",
  "CANCELLED"
]

export const ItemStatusEnum = {
  IN_STOCK: "IN_STOCK",
  SOLD: "SOLD",
  MISSING: "MISSING",
  RETURNED: "RETURNED"
}

export const ItemStatusList = [
  "IN_STOCK",
  "SOLD",
  "MISSING",
  "RETURNED"
]

export const ItemMasterStatusEnum = {
  AVAILABLE: "AVAILABLE",
  NOT_AVAILABLE: "NOT_AVAILABLE",
}

export const ItemMasterStatusList = [
  "AVAILABLE",
  "NOT_AVAILABLE",
]
