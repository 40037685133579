import React,{ useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { searchDocuments } from "../database/standard/search";
import { searchNextDocuments } from "../database/standard/searchNext";
import { searchPrevDocuments } from "../database/standard/searchPrev";
import CustomLoader from '../ui-common/CustomLoader';
import SelectOnlyDropdown from "../ui-common/SelectOnlyDropdown";
import FullTextSearch from "../../utilities/FullTextSearch";


const PoItemAddModal = (props) => {

  const dbResultLimit = 5;
  // Items List
  const [origItems, setOrigItems] = useState([]);
  const [items, setItems] = useState([]);
  // First Document in query pagination
  const [firstVisible, setFirstVisible] = useState();
  // Last Document in query pagination
  const [lastVisible, setLastVisible] = useState();
  // Previous button switch
  const [disablePrevButton, setDisablePrevButton] = useState(true);
  // Next button switch
  const [disableNextButton, setDisableNextButton] = useState(true);
  // Search Value
  const [searchInput, setSearchInput] = useState('');

  // Dropdown selections for query filter
  const [selectedDivision, setSelectedDivision] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedItemType, setSelectedItemType] = useState('');
  const [selectedItemSubType, setSelectedItemSubType] = useState('');

  // User Display Name
  const displayName = props.displayName;
  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[displayName]);

  function selectedBrandListener(brand) {
    if (brand === "BLANK") {
      setSelectedBrand("");
    } else {
      setSelectedBrand(brand);
    }
  }
  function selectedDivisionListener(division) {
    if (division === "BLANK") {
      setSelectedDivision("");
    } else {
      setSelectedDivision(division);
    }
  }
  function selectedItemTypeListener(itemType) {
    if (itemType === "BLANK") {
      setSelectedItemType("");
    } else {
      setSelectedItemType(itemType);
    }
  }
  function selectedItemSubTypeListener(itemSubType) {
    if (itemSubType === "BLANK") {
      setSelectedItemSubType("");
    } else {
      setSelectedItemSubType(itemSubType);
    }
  }
  function searchInputListener(event) {
    setSearchInput(event.target.value);
    setItems(FullTextSearch(origItems, event.target.value, "itemId", true));
  }


  function parseQueryFilter() {
    const queryFilters = [];
    if (selectedBrand) {
      queryFilters.push({key:"brand",inputValue:selectedBrand});
    }
    if (selectedDivision) {
      queryFilters.push({key:"division",inputValue:selectedDivision});
    }
    if (selectedItemType) {
      queryFilters.push({key:"itemType",inputValue:selectedItemType});
    }
    if (selectedItemSubType) {
      queryFilters.push({key:"itemSubType",inputValue:selectedItemSubType});
    }
    return queryFilters;
  }

  async function generateList() {
    console.log('USE EFFECT EXECUTED');

    setLoaderState(true);
    const queryFilters = parseQueryFilter();
    let itemResult;
    try {
      itemResult = await searchDocuments("item-master", queryFilters, dbResultLimit, "itemId", "asc");
      if (!itemResult) {
        setLoaderState(false);
        return;
      }
    } catch(error) {
      console.log(`Error in DB query: ${error}`);
      setLoaderState(false);
      return;
    }
    setOrigItems(itemResult.docs);
    setItems(itemResult.docs);
    setFirstVisible(itemResult.firstVisibleDocument);
    setLastVisible(itemResult.lastVisibleDocument);
    if (itemResult.isNextAvailable) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
    setDisablePrevButton(true);
    setLoaderState(false);
  }

  async function getNext() {
    setLoaderState(true);
    const queryFilters = parseQueryFilter();
    const itemResult = await searchNextDocuments("item-master", queryFilters, dbResultLimit, lastVisible, "itemId", "asc");
    setItems(itemResult.docs);
    setFirstVisible(itemResult.firstVisibleDocument);
    setLastVisible(itemResult.lastVisibleDocument);
    if (itemResult.isNextAvailable) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
    setDisablePrevButton(false);
    setLoaderState(false);
  }

  async function getPrev() {
    setLoaderState(true);
    const queryFilters = parseQueryFilter();
    const itemResult = await searchPrevDocuments("item-master", queryFilters, dbResultLimit, firstVisible, "itemId", "asc");
    setItems(itemResult.docs);
    setFirstVisible(itemResult.firstVisibleDocument);
    setLastVisible(itemResult.lastVisibleDocument);
    if (itemResult.isPrevAvailable) {
      setDisablePrevButton(false);
    } else {
      setDisablePrevButton(true);
    }
    setDisableNextButton(false);
    setLoaderState(false);
  }

  // Props callback function for parent props
  function addSelectedItem(selectedItem) {
    props.onConfirmation(selectedItem);
    setShow(false);
  }


  return (
    <React.Fragment>
    <Button variant="primary" onClick={handleShow}>
      ADD ITEM
    </Button>
      <CustomLoader
        isActive={loaderState}
      >
      <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      >
        <Modal.Header closeButton>
        <Modal.Title>Search Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Form>
        <Form.Group className="mb-3" controlId="inputItem">
        <InputGroup className="mb-1" size="sm">
        <InputGroup.Text id="element">Brand</InputGroup.Text>
          <SelectOnlyDropdown
            dbName="brand"
            elementObjectKey="brand"
            selectedElement={selectedBrand}
            onSelectedElement={selectedBrandListener}
          />
        &nbsp; &nbsp;
        <InputGroup.Text id="element">Item Type</InputGroup.Text>
          <SelectOnlyDropdown
            dbName="item-type"
            elementObjectKey="itemType"
            selectedElement={selectedItemType}
            onSelectedElement={selectedItemTypeListener}
          />
        &nbsp; &nbsp;
        <InputGroup.Text id="element">Item Sub Type</InputGroup.Text>
          <SelectOnlyDropdown
            dbName="item-sub-type"
            elementObjectKey="itemSubType"
            selectedElement={selectedItemSubType}
            onSelectedElement={selectedItemSubTypeListener}
          />
        </InputGroup>
        <InputGroup className="mb-1" size="sm" style={{marginTop:10}}>
        <InputGroup.Text id="element">Division</InputGroup.Text>
          <SelectOnlyDropdown
            dbName="division"
            elementObjectKey="division"
            selectedElement={selectedDivision}
            onSelectedElement={selectedDivisionListener}
          />
        </InputGroup>
        <InputGroup className="mb-1" size="sm" style={{marginTop:10}}>
          <Button variant="primary" onClick={() => generateList()}>Generate List</Button>
        </InputGroup>
        <InputGroup className="mb-1" size="sm"style={{marginTop:10}}>
        <InputGroup.Text id="brand-name">Item Id</InputGroup.Text>
        <Form.Control
          type="searchItemId" placeholder="Type Search Text here" value={searchInput}
          onChange={searchInputListener}
        />
        </InputGroup>
        </Form.Group>
        </Form>
          <ListGroup style={{overflow:"scroll", maxHeight:"350px"}}>
          {
            items.map((val, index) => (
              <ListGroup.Item key={val.itemId} action onClick={() => {addSelectedItem(val)}}>
                <div className="ms-2 me-auto">
                  <b>Item Id</b>: {val.itemId}
                  &nbsp; <b>Item Description:</b> {val.itemDescription}
                  &nbsp; <b>Brand:</b> {val.brand}
                  &nbsp; <b>Division:</b> {val.Division}
                  &nbsp; <b>Item Type:</b> {val.itemType}
                  &nbsp; <b>Item Sub Type:</b> {val.itemSubType}
                </div>
              </ListGroup.Item>
            ))
          }
          </ListGroup>
          <br></br>
            { disablePrevButton
              ? null
              : <Button variant="primary" onClick={() => getPrev()}>Previous</Button>
            }
            &nbsp;&nbsp;
            { disableNextButton
              ? null
              : <Button variant="primary" onClick={() => getNext()}>Next</Button>
            }
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
      </CustomLoader>
    </React.Fragment>
  );
}

export default PoItemAddModal;
