import { Fragment, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import classes from './SideBar.module.css';
import Badge from 'react-bootstrap/Badge';
import MenuLinks from './MenuLinks';


const SideBar = (props) => {
  // Sidebar open and close state
  const [show, setShow] = useState(false);
  // PO SubMenu open and close state
  const [poMenuShow, setPoMenuShow] = useState(false);
  // Items SubMenu open and close state
  const [itemsMenuShow, setItemsMenuShow] = useState(false);
  // Items SubMenu open and close state
  const [dataLoaderMenuShow, setdataLoaderMenuShow] = useState(false);
  // Supplier SubMenu open and close state
  const [supplierMenuShow, setSupplierMenuShow] = useState(false);
  // User SubMenu open and close state
  const [userSubMenuShow, setUserSubMenuShow] = useState(false);
  // Admin SubMenu open and close state
  const [adminSubMenuShow, setAdminSubMenuShow] = useState(false);
  // Super Admin SubMenu open and close state
  const [superAdminSubMenuShow, setSuperAdminSubMenuShow] = useState(false);

  const displayName = props.displayName;
  const roles = props.roles;

  function handleClose () {
    setShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function handleShow () {setShow(true)};

  function toggleSupplierMenuShow() {
    setSupplierMenuShow(!supplierMenuShow);
    setItemsMenuShow(false);
    setdataLoaderMenuShow(false);
    setPoMenuShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function toggleItemsMenuShow() {
    setItemsMenuShow(!itemsMenuShow);
    setdataLoaderMenuShow(false);
    setPoMenuShow(false);
    setSupplierMenuShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function toggleDataLoaderMenuShow() {
    setdataLoaderMenuShow(!dataLoaderMenuShow);
    setItemsMenuShow(false);
    setPoMenuShow(false);
    setSupplierMenuShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function togglePoMenuShow() {
    setPoMenuShow(!poMenuShow);
    setItemsMenuShow(false);
    setdataLoaderMenuShow(false);
    setSupplierMenuShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function toggleUserSubMenu() {
    setUserSubMenuShow(!userSubMenuShow);
    setItemsMenuShow(false);
    setdataLoaderMenuShow(false);
    setSupplierMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
    setPoMenuShow(false);
  }
  function toggleAdminSubMenu() {
    setAdminSubMenuShow(!adminSubMenuShow);
    setItemsMenuShow(false);
    setdataLoaderMenuShow(false);
    setSupplierMenuShow(false);
    setUserSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
    setPoMenuShow(false);
  }
  function toggleSuperAdminSubMenu() {
    setSuperAdminSubMenuShow(!superAdminSubMenuShow);
    setItemsMenuShow(false);
    setdataLoaderMenuShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setPoMenuShow(false);
  }

  return (
    <Fragment>
      <Button variant="primary" onClick={handleShow} size="lg" >
        MENU &nbsp; <i className="fa-solid fa-bars fa-xl"></i>
      </Button>
      <div style={{margin:10}}>
        { roles.length > 0
          ? roles.map((role) => (
              <Badge
                pill
                bg="info"
                text="dark"
                style={{marginRight: "4px"}}
              >
                {role}
              </Badge>
            ))
          : null
        }
        <Badge
          pill
          bg="warning"
          text="dark"
        >
          {displayName}
        </Badge>
      </div>
      <Offcanvas show={show} onHide={handleClose} style={
        {
          backgroundColor:"#044599",
          color: "whitesmoke",
          width: 200
        }
      }>
        <Offcanvas.Header closeButton closeVariant='white'>
          <Offcanvas.Title>MENU</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className={classes.linkList}>
            <li>
              <Link to='/pocreate'>
                <Button>
                  Home
                </Button>
              </Link>
            </li>

            <li>
              <Button onClick={togglePoMenuShow}>
                Warehouse
              </Button>
            </li>
            <MenuLinks
              collapse={poMenuShow}
              objectLinksList={[
                {label:"Purchase Order Request", link:"/pocreate"},
                {label:"Search", link:"/posearch"}
              ]}
            />

            <li>
              <Button onClick={toggleItemsMenuShow}>
                Backend
              </Button>
            </li>
            <MenuLinks
              collapse={itemsMenuShow}
              objectLinksList={[
                {label:"Item Create", link:"/itemmastercreate"},
                {label:"Item Search", link:"/itemmastersearch"},
                {label:"Brand", link:"/brandinputsearch"},
                {label:"Division", link:"/divisioninputsearch"},
                {label:"Item Type", link:"/itemtypeinputsearch"},
                {label:"Item Sub Type", link:"/itemsubtypeinputsearch"},
                {label:"Supplier Create", link:"/suppliercreate"},
                {label:"Supplier Search", link:"/suppliersearch"}
              ]}
            />

            <li>
              <Button onClick={toggleDataLoaderMenuShow}>
                Data Loading
              </Button>
            </li>
            <MenuLinks
              collapse={dataLoaderMenuShow}
              objectLinksList={[
                {label:"Receiving Items", link:"/receivingmassloading"},
                {label:"Item Master", link:"/itemmastermassloading"},
                {label:"Job Search", link:"/jobs"}
              ]}
            />

            <li>
              <Button onClick={toggleSupplierMenuShow}>
                Approver Admin
              </Button>
            </li>
            <MenuLinks
              collapse={supplierMenuShow}
              objectLinksList={[
                {label:"PO Modification", link:"/posearch"},
              ]}
            />

            <li>
              <Button onClick={toggleUserSubMenu}>
                User
              </Button>
            </li>
            <MenuLinks
              collapse={userSubMenuShow}
              objectLinksList={[
                {label:"User Profile", link:"/userprofile"},
                {label:"Help", link:"/userhelp"}
              ]}
            />

            <li>
              { props.isAdmin
                ? <Button onClick={toggleAdminSubMenu}>
                    Admin
                  </Button>
                : <Button onClick={toggleAdminSubMenu}>
                    Admin
                  </Button>
              }
            </li>
            <MenuLinks
              collapse={adminSubMenuShow}
              objectLinksList={[
                {label:"Create User", link:"/createuser"},
                {label:"Manage Users", link:"/manageusers"},
                {label:"Broadcasts", link:"/managebroadcasts"},
                {label:"Help", link:"/adminhelp"}
              ]}
            />

            <li>
              { props.isSuperAdmin
                ? <Button onClick={toggleSuperAdminSubMenu}>
                    Super Admin
                  </Button>
                : null
              }
            </li>
            <MenuLinks
              collapse={superAdminSubMenuShow}
              objectLinksList={[
                {label:"Manage Admins", link:"/manageadmins"},
                {label:"Manage Help", link:"/superadminhelp"}
              ]}
            />


            <li>
              <Link to='/signout'>
              <Button>
                Sign Out
              </Button>
            </Link>
            </li>

          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  )
}

export default SideBar;
