import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ListGroup from 'react-bootstrap/ListGroup';
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./ItemMasterSearch.module.css";
import { firebaseObserver } from '../../firebaseConfig';
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import { searchDocuments } from "../../components/database/standard/search";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { listDocuments } from "../../components/database/standard/get";
import { searchNextDocuments } from "../../components/database/standard/searchNext";
import { searchPrevDocuments } from "../../components/database/standard/searchPrev";
import FullTextSearch from "../../utilities/FullTextSearch";


const ItemMasterSearch = () => {

  const dbResultLimit = 100;
  // Items List
  const [origItems, setOrigItems] = useState([]);
  const [items, setItems] = useState([]);
  // First Document in query pagination
  const [firstVisible, setFirstVisible] = useState();
  // Last Document in query pagination
  const [lastVisible, setLastVisible] = useState();
  // Previous button switch
  const [disablePrevButton, setDisablePrevButton] = useState(true);
  // Next button switch
  const [disableNextButton, setDisableNextButton] = useState(true);
  // Search Value
  const [searchInput, setSearchInput] = useState('');

  // Brand List
  const [brandList, setBrandList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  // Division List
  const [divisionList, setDivisionList] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState('');
  // Item Type List
  const [itemTypeList, setItemTypeList] = useState([]);
  const [selectedItemType, setSelectedItemType] = useState('');
  // Item SubType List
  const [itemSubTypeList, setItemSubTypeList] = useState([]);
  const [selectedItemSubType, setSelectedItemSubType] = useState('');

  // User Auth Context
  const { user } = useAuthContext();
  // User Display Name
  const [displayName, setDisplayName] = useState(user?.displayName);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
    });
    getBrandList();
    getDivisionList();
    getItemTypeList();
    getItemSubTypeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[displayName]);

  async function getBrandList() {
    const queryResult = await listDocuments("brand", 'brand', 'brand', 'asc');
    setBrandList([...[{brand:"BLANK"}],...queryResult]);
  }
  async function getDivisionList() {
    const queryResult = await listDocuments("division", 'division', 'division', 'asc');
    setDivisionList([...[{division:"BLANK"}],...queryResult]);
  }
  async function getItemTypeList() {
    const queryResult = await listDocuments("item-type", 'itemType', 'itemType', 'asc');
    setItemTypeList([...[{itemType:"BLANK"}],...queryResult]);
  }
  async function getItemSubTypeList() {
    const queryResult = await listDocuments("item-sub-type", 'itemSubType', 'itemSubType', 'asc');
    setItemSubTypeList([...[{itemSubType:"BLANK"}],...queryResult]);
  }

  function selectedBrandListener(brand) {
    if (brand === "BLANK") {
      setSelectedBrand("");
    } else {
      setSelectedBrand(brand);
    }
  }
  function selectedDivisionListener(division) {
    if (division === "BLANK") {
      setSelectedDivision("");
    } else {
      setSelectedDivision(division);
    }
  }
  function selectedItemTypeListener(itemType) {
    if (itemType === "BLANK") {
      setSelectedItemType("");
    } else {
      setSelectedItemType(itemType);
    }
  }
  function selectedItemSubTypeListener(itemSubType) {
    if (itemSubType === "BLANK") {
      setSelectedItemSubType("");
    } else {
      setSelectedItemSubType(itemSubType);
    }
  }
  function searchInputListener(event) {
    setSearchInput(event.target.value);
    setItems(FullTextSearch(origItems, event.target.value, "itemId", true));
  }

  function parseQueryFilter() {
    const queryFilters = [];
    if (selectedBrand) {
      queryFilters.push({key:"brand",inputValue:selectedBrand});
    }
    if (selectedDivision) {
      queryFilters.push({key:"division",inputValue:selectedDivision});
    }
    if (selectedItemType) {
      queryFilters.push({key:"itemType",inputValue:selectedItemType});
    }
    if (selectedItemSubType) {
      queryFilters.push({key:"itemSubType",inputValue:selectedItemSubType});
    }
    return queryFilters;
  }

  async function generateList() {
    console.log('USE EFFECT EXECUTED');

    setLoaderState(true);
    const queryFilters = parseQueryFilter();
    let itemResult;
    try {
      itemResult = await searchDocuments("item-master", queryFilters, dbResultLimit, "itemId", "asc");
      if (!itemResult) {
        setLoaderState(false);
        return;
      }
    } catch(error) {
      console.log(`Error in DB query: ${error}`);
      setLoaderState(false);
      return;
    }
    setOrigItems(itemResult.docs);
    setItems(itemResult.docs);
    setFirstVisible(itemResult.firstVisibleDocument);
    setLastVisible(itemResult.lastVisibleDocument);
    if (itemResult.isNextAvailable) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
    setDisablePrevButton(true);
    setLoaderState(false);
  }

  async function getNext() {
    setLoaderState(true);
    const queryFilters = parseQueryFilter();
    const itemResult = await searchNextDocuments("item-master", queryFilters, dbResultLimit, lastVisible, "itemId", "asc");
    setItems(itemResult.docs);
    setFirstVisible(itemResult.firstVisibleDocument);
    setLastVisible(itemResult.lastVisibleDocument);
    if (itemResult.isNextAvailable) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
    setDisablePrevButton(false);
    setLoaderState(false);
  }

  async function getPrev() {
    setLoaderState(true);
    const queryFilters = parseQueryFilter();
    const itemResult = await searchPrevDocuments("item-master", queryFilters, dbResultLimit, firstVisible, "itemId", "asc");
    setItems(itemResult.docs);
    setFirstVisible(itemResult.firstVisibleDocument);
    setLastVisible(itemResult.lastVisibleDocument);
    if (itemResult.isPrevAvailable) {
      setDisablePrevButton(false);
    } else {
      setDisablePrevButton(true);
    }
    setDisableNextButton(false);
    setLoaderState(false);
  }

  function itemMasterDetailsLink(itemId) {
    navigate(`/itemmasterdetails/${itemId}`);
  }


  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center><h3 style={{marginTop:15}}>Search Item</h3></center>
      <div className={classes.mainInputContainer}>
      <center>
      <Form>
      <Form.Group className="mb-3" controlId="inputItem">
      <InputGroup className="mb-1" size="sm">
      <InputGroup.Text id="element">Brand</InputGroup.Text>
      <DropdownButton id="supplier-dropdown-button" title={selectedBrand}>
        {
          brandList.map((val) => (
            <Dropdown.Item key={val.brand} onClick={() => {selectedBrandListener(val.brand)}}>{val.brand}</Dropdown.Item>
          ))
        }
      </DropdownButton>
      &nbsp; &nbsp;
      <InputGroup.Text id="element">Division </InputGroup.Text>
        <DropdownButton id="search-options-dropdown-button" title={selectedDivision}>
          {
            divisionList.map((val) => (
              <Dropdown.Item key={val.division} onClick={() => {selectedDivisionListener(val.division)}}>{val.division}</Dropdown.Item>
            ))
          }
        </DropdownButton>
      &nbsp; &nbsp;
      <InputGroup.Text id="element">Item Type</InputGroup.Text>
        <DropdownButton id="search-options-dropdown-button" title={selectedItemType}>
        {
          itemTypeList.map((val) => (
            <Dropdown.Item key={val.itemType} onClick={() => {selectedItemTypeListener(val.itemType)}}>{val.itemType}</Dropdown.Item>
          ))
        }
        </DropdownButton>
      &nbsp; &nbsp;
      <InputGroup.Text id="element">Item Sub Type</InputGroup.Text>
        <DropdownButton id="search-options-dropdown-button" title={selectedItemSubType}>
        {
          itemSubTypeList.map((val) => (
            <Dropdown.Item key={val.itemSubType} onClick={() => {selectedItemSubTypeListener(val.itemSubType)}}>{val.itemSubType}</Dropdown.Item>
          ))
        }
        </DropdownButton>
      </InputGroup>
      <InputGroup className="mb-1" size="sm" style={{display:"inline-block", marginTop:5}}>
        <Button variant="primary" onClick={() => generateList()}>Generate List</Button>
      </InputGroup>
      <InputGroup className="mb-1" size="sm"style={{marginTop:5}}>
      <InputGroup.Text id="brand-name">Item Id</InputGroup.Text>
      <Form.Control
        type="searchItemId" placeholder="Type Search Text here" value={searchInput}
        onChange={searchInputListener}
      />
      </InputGroup>
      </Form.Group>
      </Form>
        <ListGroup style={{overflow:"scroll", maxHeight:"350px"}}>
        {
          items.map((val, index) => (
            <ListGroup.Item key={val.itemId} action onClick={() => {itemMasterDetailsLink(val.itemId)}}>
              <div className="ms-2 me-auto">
                <b>Item Id</b>: {val.itemId}
                &nbsp; <b>Item Description:</b> {val.itemDescription}
                &nbsp; <b>Item Type:</b> {val.itemType}
                &nbsp; <b>Brand:</b> {val.brand}
                &nbsp; <b>Color:</b> {val.itemColor}
              </div>
            </ListGroup.Item>
          ))
        }
        </ListGroup>
        <br></br>
          { disablePrevButton
            ? null
            : <Button variant="primary" onClick={() => getPrev()}>Previous</Button>
          }
          &nbsp;&nbsp;
          { disableNextButton
            ? null
            : <Button variant="primary" onClick={() => getNext()}>Next</Button>
          }
        </center>
      </div>
    </div>
    </CustomLoader>
  );
}

export default ItemMasterSearch;
