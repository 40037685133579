import * as React from "react";
import { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./ItemMasterInput.module.css";
import { firebaseObserver } from '../../firebaseConfig';
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import InsertDataDropdownModal from "../../components/functional/InsertDataDropdownModal";
import { insertDocument } from "../../components/database/standard/insert";
import CustomFeedbackValidation from "../../components/ui-common/CustomFeedbackValidation";


const ItemMasterInput = () => {

  const [brand, setBrand] = useState('');
  const [division, setDivision] = useState('');
  const [itemType, setItemType] = useState('');
  const [itemSubType, setItemSubType] = useState('');
  const [itemId, setItemId] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [serialMinLength, setSerialMinLength] = useState(1);
  const [serialMaxLength, setSerialMaxLength] = useState(3);
  const [upc, setUpc] = useState('');
  const [purchasePrice, setPurchasePrice] = useState('');
  const [sellingPrice1, setSellingPrice1] = useState('');
  const [sellingPrice2, setSellingPrice2] = useState('');
  const [sellingPrice3, setSellingPrice3] = useState('');
  const [sellingPrice4, setSellingPrice4] = useState('');
  const [sellingPrice5, setSellingPrice5] = useState('');

  // User Auth Context
  const { user } = useAuthContext();
  // User Display Name
  const [displayName, setDisplayName] = useState(user?.displayName);
  // Create user Result
  const [submitSuccess, setSubmitSuccess] = useState(false);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // form validation state
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[displayName]);

  // Input Listeners
  function selectedBrand(brand) {
    setBrand(brand);
  }
  function selectedDivision(division) {
    setDivision(division);
  }
  function selectedItemType(itemType) {
    setItemType(itemType);
  }
  function selectedItemSubType(itemSubType) {
    setItemSubType(itemSubType);
  }
  function itemIdListener(event) {
    setItemId(event.target.value);
  }
  function itemDescriptionListener(event) {
    setItemDescription(event.target.value);
  }
  function upcListener(event) {
    setUpc(event.target.value);
  }
  function serialMinLengthListener(event) {
    setSerialMinLength(event.target.value);
  }
  function serialMaxLengthListener(event) {
    setSerialMaxLength(event.target.value);
  }
  function purchasePriceListener(event) {
    setPurchasePrice(event.target.value);
  }
  function sellingPriceListener1(event) {
    setSellingPrice1(event.target.value);
  }
  function sellingPriceListener2(event) {
    setSellingPrice2(event.target.value);
  }
  function sellingPriceListener3(event) {
    setSellingPrice3(event.target.value);
  }
  function sellingPriceListener4(event) {
    setSellingPrice4(event.target.value);
  }
  function sellingPriceListener5(event) {
    setSellingPrice5(event.target.value);
  }

  async function createItem(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }
    if (!validateDropDowns()) {
      return;
    }
    const itemMasterData = {
      upc: upc,
      brand: brand,
      division: division,
      itemType: itemType,
      itemSubType: itemSubType,
      itemId: itemId,
      itemDescription: itemDescription,
      serialMinLength: serialMinLength,
      serialMaxLength: serialMaxLength,
      purchasePrice: purchasePrice,
      sellingPrice1: sellingPrice1,
      sellingPrice2: sellingPrice2,
      sellingPrice3: sellingPrice3,
      sellingPrice4: sellingPrice4,
      sellingPrice5: sellingPrice5,
      isAvailable: true
    };
    setLoaderState(true);
    const insertResult = await insertDocument("item-master", "itemId", itemMasterData, true, displayName);
    if (!insertResult) {
      setLoaderState(false);
      return;
    }
    setLoaderState(false);
    setSubmitSuccess(true);
  }

  function validateDropDowns() {
    if (!brand || !division || !itemType || !itemSubType) {
      return false;
    }
    return true;
  }


  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center><h3 style={{marginTop:15}}>Create Item</h3></center>
        { submitSuccess
          ? <center>
              <div className={classes.mainInputContainer}>
                <h3>Item Successfully Created.</h3>
                Item ID: {itemId}
                <br></br><br></br>
                <Button variant="primary" onClick={() => {navigate(`/itemmasterdetails/${itemId}`)}}>View Item Details</Button>
                &nbsp;
                <Button variant="primary" onClick={() => {window.location.reload(false)}}>Create Another Item</Button>
                &nbsp;
                <Button variant="primary" onClick={() => {navigate('/')}}>Exit</Button>
              </div>
            </center>
          : <div className={classes.mainInputContainer}>
            <Form noValidate validated={validated} onSubmit={createItem}>
              <Form.Group className="mb-3" controlId="inputItem">
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="brand-name">Brand</InputGroup.Text>
                <InsertDataDropdownModal
                  validated={validated}
                  selectedElement={brand}
                  onSelectedElement={selectedBrand}
                  displayName={displayName}
                  elementObjectKey="brand"
                  dbName="brand"
                  label="Brand"
                  required={true}
                />
                </InputGroup>
                <CustomFeedbackValidation
                  validated={validated}
                  evaluatedValue={brand}
                  label="Brand"
                />
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="division">Division</InputGroup.Text>
                <InsertDataDropdownModal
                  validated={validated}
                  selectedElement={division}
                  onSelectedElement={selectedDivision}
                  displayName={displayName}
                  elementObjectKey="division"
                  dbName="division"
                  label="Division"
                  required={true}
                />
                </InputGroup>
                <CustomFeedbackValidation
                  validated={validated}
                  evaluatedValue={division}
                  label="Division"
                />
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="item-types">Item Type</InputGroup.Text>
                <InsertDataDropdownModal
                  validated={validated}
                  selectedElement={itemType}
                  onSelectedElement={selectedItemType}
                  displayName={displayName}
                  elementObjectKey="itemType"
                  dbName="item-type"
                  label="Item Type"
                  required={true}
                />
                </InputGroup>
                <CustomFeedbackValidation
                  validated={validated}
                  evaluatedValue={itemType}
                  label="Item Type"
                />
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="item-sub-type">Item Sub Type</InputGroup.Text>
                <InsertDataDropdownModal
                  validated={validated}
                  selectedElement={itemSubType}
                  onSelectedElement={selectedItemSubType}
                  displayName={displayName}
                  elementObjectKey="itemSubType"
                  dbName="item-sub-type"
                  label="Item Sub Type"
                  required={true}
                />
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="item-id">Item Id</InputGroup.Text>
                <Form.Control
                  required
                  type="text"
                  placeholder="item Id"
                  value={itemId}
                  onChange={itemIdListener}
                />
                <Form.Control.Feedback type="invalid">
                  Item Id should not be blank.
                </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="item-description">Item Description</InputGroup.Text>
                <Form.Control
                  required
                  type="text"
                  placeholder="item Description"
                  value={itemDescription}
                  onChange={itemDescriptionListener}
                />
                <Form.Control.Feedback type="invalid">
                  Item Description should not be blank.
                </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="serialMinLength">Serial Min Length</InputGroup.Text>
                <Form.Control
                  required
                  type="number"
                  value={serialMinLength}
                  onChange={serialMinLengthListener}
                />
                <Form.Control.Feedback type="invalid">
                  Serial minimum length should not be blank and should be a number.
                </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="serialMaxLength">Serial Max Length</InputGroup.Text>
                <Form.Control
                  required
                  type="number"
                  value={serialMaxLength}
                  onChange={serialMaxLengthListener}
                />
                <Form.Control.Feedback type="invalid">
                  Serial maximum length should not be blank and should be a number.
                </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="upc">UPC Code</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="UPC CODE (optional)"
                  value={upc}
                  onChange={upcListener}
                />
                </InputGroup>
                <InputGroup hasValidation className="mb-1" size="sm">
                <InputGroup.Text id="price">Purchase Price</InputGroup.Text>
                <Form.Control
                  required
                  type="text"
                  pattern="^\d+(\.\d{1,2})?$"
                  placeholder="000.00"
                  value={purchasePrice}
                  onChange={purchasePriceListener}
                />
                <Form.Control.Feedback type="invalid">
                  Purchase price should be a number and not be blank.
                </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="sellingprice1">Selling Price 1</InputGroup.Text>
                <Form.Control
                  type="text"
                  pattern="^\d+(\.\d{1,2})?$"
                  placeholder="000.00 (optional)"
                  value={sellingPrice1}
                  onChange={sellingPriceListener1}
                />
                <Form.Control.Feedback type="invalid">
                  Selling Price should be a number.
                </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="sellingprice2">Selling Price 2</InputGroup.Text>
                <Form.Control
                  type="text"
                  pattern="^\d+(\.\d{1,2})?$"
                  placeholder="000.00 (optional)"
                  value={sellingPrice2}
                  onChange={sellingPriceListener2}
                />
                <Form.Control.Feedback type="invalid">
                  Selling Price should be a number.
                </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="sellingprice3">Selling Price 3</InputGroup.Text>
                <Form.Control
                  type="text"
                  pattern="^\d+(\.\d{1,2})?$"
                  placeholder="000.00 (optional)"
                  value={sellingPrice3}
                  onChange={sellingPriceListener3}
                />
                <Form.Control.Feedback type="invalid">
                  Selling Price should be a number.
                </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="sellingprice4">Selling Price 4</InputGroup.Text>
                <Form.Control
                  type="text"
                  pattern="^\d+(\.\d{1,2})?$"
                  placeholder="000.00 (optional)"
                  onChange={sellingPriceListener4}
                />
                <Form.Control.Feedback type="invalid">
                  Selling Price should be a number.
                </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="sellingprice5">Selling Price 5</InputGroup.Text>
                <Form.Control
                  type="text"
                  pattern="^\d+(\.\d{1,2})?$"
                  placeholder="000.00 (optional)"
                  onChange={sellingPriceListener5}
                />
                <Form.Control.Feedback type="invalid">
                  Selling Price should be a number.
                </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <center><Button type="submit">Create Item</Button></center>
            </Form>
            </div>
      }
    </div>
    </CustomLoader>
  );
}

export default ItemMasterInput;
