import * as React from "react";
import { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./ItemMasterDetails.module.css";
import { firebaseObserver } from '../../firebaseConfig';
import { useNavigate, useParams } from "react-router-dom";
import InsertDataDropdownModal from "../../components/functional/InsertDataDropdownModal";
import { useAuthContext } from "../../components/context/useAuthContext";
import { Timestamp } from "firebase/firestore/lite";
import { getDocument } from "../../components/database/standard/get";
import { updateDocument } from "../../components/database/standard/update";
import CustomFeedbackValidation from "../../components/ui-common/CustomFeedbackValidation";


const ItemMasterDetails = () => {

  const params = useParams();
  const [itemDocId, setItemDocId] = useState('');
  const [brand, setBrand] = useState('');
  const [division, setDivision] = useState('');
  const [itemType, setItemType] = useState('');
  const [itemSubType, setItemSubType] = useState('');
  const [itemId, setItemId] = useState(params.itemId);
  const [itemDescription, setItemDescription] = useState('');
  const [serialMinLength, setSerialMinLength] = useState('');
  const [serialMaxLength, setSerialMaxLength] = useState('');
  const [upc, setUpc] = useState('');
  const [purchasePrice, setPurchasePrice] = useState('');
  const [sellingPrice1, setSellingPrice1] = useState('');
  const [sellingPrice2, setSellingPrice2] = useState('');
  const [sellingPrice3, setSellingPrice3] = useState('');
  const [sellingPrice4, setSellingPrice4] = useState('');
  const [sellingPrice5, setSellingPrice5] = useState('');
  const [isAvailable, setIsAvailable] = useState('');
  // modified by
  const [modifiedBy, setModifiedBy] = useState('');
  const [dateModified, setDateModified] = useState('');

  // User Auth Context
  const { user } = useAuthContext();
  // User Display Name
  const [displayName, setDisplayName] = useState(user?.displayName);
  // Edit Switch
  const [enableEdit, setEnableEdit] = useState(false);
  // Create user Result
  const [submitSuccess, setSubmitSuccess] = useState(false);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // form validation state
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
    });
    searchItem(params.itemId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[displayName, params.itemId]);

  // DB call functions
  async function searchItem(itemId) {
    const itemResult = await getDocument("item-master", "itemId", itemId);
    setItemDocId(itemResult.docs[0].id);
    populateFields(itemResult.docs[0].data());
  }

  // end db call functions

  // Input Listeners
  function selectedBrand(brand) {
    setBrand(brand);
  }
  function selectedDivision(division) {
    setDivision(division);
  }
  function selectedItemType(itemType) {
    setItemType(itemType);
  }
  function selectedItemSubType(itemSubType) {
    setItemSubType(itemSubType);
  }
  function itemIdListener(event) {
    setItemId(event.target.value);
  }
  function itemDescriptionListener(event) {
    setItemDescription(event.target.value);
  }
  function upcListener(event) {
    setUpc(event.target.value);
  }
  function serialMinLengthListener(event) {
    setSerialMinLength(event.target.value);
  }
  function serialMaxLengthListener(event) {
    setSerialMaxLength(event.target.value);
  }
  function purchasePriceListener(event) {
    setPurchasePrice(event.target.value);
  }
  function sellingPriceListener1(event) {
    setSellingPrice1(event.target.value);
  }
  function sellingPriceListener2(event) {
    setSellingPrice2(event.target.value);
  }
  function sellingPriceListener3(event) {
    setSellingPrice3(event.target.value);
  }
  function sellingPriceListener4(event) {
    setSellingPrice4(event.target.value);
  }
  function sellingPriceListener5(event) {
    setSellingPrice5(event.target.value);
  }
  function isAvailableListener(event) {
    setIsAvailable(event.target.checked);
  }

  async function editItem(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      console.log('HERE2');
      return;
    }
    if (!validateDropDowns()) {
      console.log('HERE3');
      return;
    }
    const itemMasterData = {
      upc: upc,
      brand: brand,
      division: division,
      itemType: itemType,
      itemSubType: itemSubType,
      itemId: itemId,
      itemDescription: itemDescription,
      serialMinLength: serialMinLength,
      serialMaxLength: serialMaxLength,
      purchasePrice: purchasePrice,
      sellingPrice1: sellingPrice1,
      sellingPrice2: sellingPrice2,
      sellingPrice3: sellingPrice3,
      sellingPrice4: sellingPrice4,
      sellingPrice5: sellingPrice5,
      isAvailable: isAvailable
    };
    console.log(itemMasterData);
    setLoaderState(true);
    const updateResult = await updateDocument("item-master", itemDocId, itemMasterData, displayName);
    if (!updateResult) {
      setLoaderState(false);
      return;
    }
    setLoaderState(false);
    setSubmitSuccess(true);
    setValidated(false);
  }

  function toggleEdit() {
    if (enableEdit) {
      alert('You will lose the changes you made. Please click the "Modify Item and Save" Button next time if you want to save your changes');
      searchItem(params.itemId);
    }
    setEnableEdit(!enableEdit);
    setValidated(false);
  }

  function populateFields(itemDocument) {
    setUpc(itemDocument.upc ?? '');
    setBrand(itemDocument.brand);
    setDivision(itemDocument.division);
    setItemType(itemDocument.itemType);
    setItemSubType(itemDocument.itemSubType ?? '');
    setItemId(itemDocument.itemId);
    setItemDescription(itemDocument.itemDescription);
    setPurchasePrice(itemDocument.purchasePrice);
    setSellingPrice1(itemDocument.sellingPrice1 ?? '');
    setSellingPrice2(itemDocument.sellingPrice2 ?? '');
    setSellingPrice3(itemDocument.sellingPrice3 ?? '');
    setSellingPrice4(itemDocument.sellingPrice4 ?? '');
    setSellingPrice5(itemDocument.sellingPrice5 ?? '');
    setIsAvailable(itemDocument.isAvailable ?? true);
    setModifiedBy(itemDocument.modifiedBy ?? displayName);
    setDateModified(itemDocument.dateModified.toDate() ?? Timestamp.now());
    setSubmitSuccess(false);
  }

  function validateDropDowns() {
    if (!brand || !division || !itemType) {
      return false;
    }
    return true;
  }

  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center style={{margin:15}}>
        <h3 style={{display:"inline"}}>Item </h3>
        { enableEdit
          ? <i className='fas fa-lock-open' onClick={toggleEdit}></i>
          : <i className='fas fa-lock' onClick={toggleEdit}></i>
        }
      </center>
        { submitSuccess
          ? <center>
              <div className={classes.mainInputContainer}>
                <h3>Item Successfully Edited.</h3>
                Item Id: {itemId}
                <br></br><br></br>
                <Button variant="primary" onClick={() => {window.location.reload(false)}}>View Item Details</Button>
                &nbsp;
                <Button variant="primary" onClick={() => {navigate('/sitemmastercreate')}}>Create Another Item</Button>
                &nbsp;
                <Button variant="primary" onClick={() => {navigate('/')}}>Exit</Button>
              </div>
            </center>
          : <div className={classes.mainInputContainer}>
            { enableEdit
              ? <Form noValidate validated={validated} onSubmit={editItem}>
                <Form.Group className="mb-3" controlId="inputItem">
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="brand">Brand</InputGroup.Text>
                  <InsertDataDropdownModal
                    validated={validated}
                    required={true}
                    selectedElement={brand}
                    onSelectedElement={selectedBrand}
                    displayName={displayName}
                    elementObjectKey="brand"
                    dbName="brand"
                    label="Brand"
                  />
                  </InputGroup>
                  <CustomFeedbackValidation
                    validated={validated}
                    evaluatedValue={brand}
                    label="Brand"
                  />
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="divison">Division</InputGroup.Text>
                  <InsertDataDropdownModal
                    validated={validated}
                    required={true}
                    selectedElement={division}
                    onSelectedElement={selectedDivision}
                    displayName={displayName}
                    elementObjectKey="division"
                    dbName="division"
                    label="Division"
                  />
                  </InputGroup>
                  <CustomFeedbackValidation
                    validated={validated}
                    evaluatedValue={division}
                    label="Division"
                  />
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="item-types">Item Type</InputGroup.Text>
                  <InsertDataDropdownModal
                    validated={validated}
                    required={true}
                    selectedElement={itemType}
                    onSelectedElement={selectedItemType}
                    displayName={displayName}
                    elementObjectKey="itemType"
                    dbName="item-type"
                    label="Item Type"
                  />
                  </InputGroup>
                  <CustomFeedbackValidation
                    validated={validated}
                    evaluatedValue={itemType}
                    label="Item Type"
                  />
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="item-sub-types">Item Sub Type</InputGroup.Text>
                  <InsertDataDropdownModal
                    validated={validated}
                    required={false}
                    selectedElement={itemSubType}
                    onSelectedElement={selectedItemSubType}
                    displayName={displayName}
                    elementObjectKey="itemSubType"
                    dbName="item-sub-type"
                    label="Item Dub Type"
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="itemId">Item Id</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Item Id"
                    value={itemId}
                    onChange={itemIdListener}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="itemDescription">Item Description</InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Item Description"
                    value={itemDescription}
                    onChange={itemDescriptionListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Item Description is required.
                  </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="serialMinLength">Serial Min Length</InputGroup.Text>
                  <Form.Control
                    required
                    type="number"
                    value={serialMinLength}
                    onChange={serialMinLengthListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Serial minimum length should not be blank and should be a number.
                  </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="serialMaxLength">Serial Max Length</InputGroup.Text>
                  <Form.Control
                    required
                    type="number"
                    value={serialMaxLength}
                    onChange={serialMaxLengthListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Serial maximum length should not be blank and should be a number.
                  </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="upc">UPC CODE</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="UPC CODE"
                    value={upc}
                    onChange={upcListener}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="purchasePrice">Purchase Price</InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    pattern="^\d+(\.\d{1,2})?$"
                    placeholder="Purchase Price"
                    value={purchasePrice}
                    onChange={purchasePriceListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Purchase price should be a number and not be blank.
                  </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="sellingPrice1">Selling Price 1</InputGroup.Text>
                  <Form.Control
                    type="text"
                    pattern="^\d+(\.\d{1,2})?$"
                    placeholder="Selling Price 1"
                    value={sellingPrice1}
                    onChange={sellingPriceListener1}
                  />
                  <Form.Control.Feedback type="invalid">
                    Selling Price should be a number.
                  </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="sellingPrice2">Selling Price 2</InputGroup.Text>
                  <Form.Control
                    type="text"
                    pattern="^\d+(\.\d{1,2})?$"
                    placeholder="Selling Price 2"
                    value={sellingPrice2}
                    onChange={sellingPriceListener2}
                  />
                  <Form.Control.Feedback type="invalid">
                    Selling Price should be a number.
                  </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="sellingPrice3">Selling Price 3</InputGroup.Text>
                  <Form.Control
                    type="text"
                    pattern="^\d+(\.\d{1,2})?$"
                    placeholder="Selling Price 3"
                    value={sellingPrice3}
                    onChange={sellingPriceListener3}
                  />
                  <Form.Control.Feedback type="invalid">
                    Selling Price should be a number.
                  </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="sellingPrice4">Selling Price 4</InputGroup.Text>
                  <Form.Control
                    type="text"
                    pattern="^\d+(\.\d{1,2})?$"
                    placeholder="Selling Price 4"
                    value={sellingPrice4}
                    onChange={sellingPriceListener4}
                  />
                  <Form.Control.Feedback type="invalid">
                    Selling Price should be a number.
                  </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="sellingPrice5">Selling Price 5</InputGroup.Text>
                  <Form.Control
                    type="text"
                    pattern="^\d+(\.\d{1,2})?$"
                    placeholder="Selling Price 5"
                    value={sellingPrice5}
                    onChange={sellingPriceListener5}
                  />
                  <Form.Control.Feedback type="invalid">
                    Selling Price should be a number.
                  </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="modifiedBy">Modified By</InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={modifiedBy}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="dateModified">Date Modified</InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={dateModified}
                    disabled
                  />
                  </InputGroup>
                  <Form.Check
                    disabled={false}
                    type="checkbox"
                    label={`Is Item Still Available?`}
                    id={"isAvailable"}
                    checked={isAvailable}
                    onChange={isAvailableListener}
                  />
                </Form.Group>
                <center><Button type="submit">Modify Item and Save</Button></center>
              </Form>
              : <React.Fragment>
                <Form>
                  <Form.Group className="mb-3" controlId="inputItemMAster">
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="brand">Brand</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Please choose brand"
                    value={brand}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="division">Division</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Please choose Division"
                    value={division}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="itemType">Item Type</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Please choose item type"
                    value={itemType}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="itemSubType">Item SubType</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Please choose item sub type"
                    value={itemSubType}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="itemId">Item Id</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Item Id"
                    value={itemId}
                    onChange={itemIdListener}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="itemDescription">Item Description</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Item Description"
                    value={itemDescription}
                    onChange={itemDescriptionListener}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="serialMinLength">Serial Min Length</InputGroup.Text>
                  <Form.Control
                    required
                    type="number"
                    value={serialMinLength}
                    onChange={serialMinLengthListener}
                    disabled
                  />
                  <Form.Control.Feedback type="invalid">
                    Serial minimum length should not be blank and should be a number.
                  </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="serialMaxLength">Serial Max Length</InputGroup.Text>
                  <Form.Control
                    required
                    type="number"
                    value={serialMaxLength}
                    onChange={serialMaxLengthListener}
                    disabled
                  />
                  <Form.Control.Feedback type="invalid">
                    Serial maximum length should not be blank and should be a number.
                  </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="upc">UPC CODE</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="UPC CODE"
                    value={upc}
                    onChange={upcListener}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="purchasePrice">Purchase Price</InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    pattern="^\d+(\.\d{1,2})?$"
                    placeholder="Purchase Price"
                    value={purchasePrice}
                    onChange={purchasePriceListener}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="sellingPriceListener1">Selling Price 1</InputGroup.Text>
                  <Form.Control
                    type="text"
                    pattern="^\d+(\.\d{1,2})?$"
                    placeholder="Selling Price 1"
                    value={sellingPrice1}
                    onChange={sellingPriceListener1}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="sellingPriceListener2">Selling Price 2</InputGroup.Text>
                  <Form.Control
                    type="text"
                    pattern="^\d+(\.\d{1,2})?$"
                    placeholder="Selling Price 2"
                    value={sellingPrice2}
                    onChange={sellingPriceListener2}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="sellingPriceListener3">Selling Price 3</InputGroup.Text>
                  <Form.Control
                    type="text"
                    pattern="^\d+(\.\d{1,2})?$"
                    placeholder="Selling Price 3"
                    value={sellingPrice3}
                    onChange={sellingPriceListener3}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="sellingPriceListener4">Selling Price 4</InputGroup.Text>
                  <Form.Control
                    type="text"
                    pattern="^\d+(\.\d{1,2})?$"
                    placeholder="Selling Price 4"
                    value={sellingPrice4}
                    onChange={sellingPriceListener4}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="sellingPriceListener5">Selling Price 5</InputGroup.Text>
                  <Form.Control
                    type="text"
                    pattern="^\d+(\.\d{1,2})?$"
                    placeholder="Selling Price 5"
                    value={sellingPrice5}
                    onChange={sellingPriceListener5}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="modifiedBy">Modified By</InputGroup.Text>
                  <Form.Control
                    type="text" value={modifiedBy}
                    disabled
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="dateModified">Date Modified</InputGroup.Text>
                  <Form.Control
                    type="text" value={dateModified}
                    disabled
                  />
                  </InputGroup>
                  <Form.Check
                    disabled
                    type="checkbox"
                    label={`Is Item Still Available?`}
                    id={"isAvailable"}
                    checked={isAvailable}
                    onChange={isAvailableListener}
                  />
                  </Form.Group>
                </Form>
                <center><Button variant="primary" onClick={() => {navigate('/');}}>Exit</Button></center>
              </React.Fragment>
            }
          </div>
        }
    </div>
    </CustomLoader>
  );
}

export default ItemMasterDetails;
