import * as React from "react";
import { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./PoInput.module.css";
import short from "short-uuid";
import { DateTime } from "luxon";
import { storage } from '../../firebaseConfig';
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import ImagePopUpModal from "../../components/ui-common/ImagePopUpModal";
import PoItemAddModal from "../../components/functional/PoItemAddModal";
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from "react-router-dom";
import { insertPurchaseOrderDocument, insertPurchaseOrderItemsDocument } from "../../components/database/purchase-order/insertPurchaseOrder";
import { useAuthContext } from "../../components/context/useAuthContext";
import { deletePurchaseOrderDocument, deletePurchaseOrderItemsDocument } from "../../components/database/purchase-order/deletePurchaseOrder";
import { getPurchasePrderItemsDocument } from "../../components/database/purchase-order/getPurchaseOrder";
import { searchSupplierDocument } from "../../components/database/supplier/searchSupplier";
import CustomFeedbackValidation from "../../components/ui-common/CustomFeedbackValidation";
import CustomInputValidation from "../../components/ui-common/CustomInputValidation";
import { PoStatusEnum } from "../../models/status";
import firebase from 'firebase/compat/app';
import RolesChecker from "../../utilities/RolesChecker";


const PoCreate = (props) => {

  // PO ID
  const [purchaseOrderId, setPurchaseOrderId] = useState(generatePoId(DateTime.local()));
  const [supplierName, setSupplierName] = useState('');
  const [dateTransaction, setDateTransaction] = useState(DateTime.local().toFormat('yyyy-MM-dd').toString());
  const [comments, setComments] = useState('');
  const [currency, setCurrency] = useState('');
  const [freightAmount, setFreightAmount] = useState('');
  // proformaInvoiceImgUrl input form listener
  const [proformaInvoiceImgUrl, setProformaInvoiceImgUrl] = useState('');
  const [proformaInvoiceFileName, setProformaInvoiceFileName] = useState('');
  const [proformaInvoiceHttpUrl, setProformaInvoiceHttpUrl] = useState('');
  // commercialInvoiceImgUrl input form listener
  const [commercialInvoiceImgUrl, setCommercialInvoiceImgUrl] = useState('');
  const [commercialInvoiceFileName, setCommercialInvoiceFileName] = useState('');
  const [commercialInvoiceHttpUrl, setCommercialInvoiceHttpUrl] = useState('');
  // Total Count of Items
  const [totalCount, setTotalCount] = useState(0);
  // PO items
  const [items, setItems] = useState([]);

  // User Auth Context
  const { user } = useAuthContext();
  // User Display Name
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [roles, setRoles] = useState([]);
  // Supplier drop down list
  const [supplierList, setSupplierList] = useState([]);
  // Create user Result
  const [submitSuccess, setSubmitSuccess] = useState(false);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // form validation state
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();
  const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("PO Input page use effect");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          // console.log(parsedUserRoles);
          // console.log(allowedRolesList);
          if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
            alert('Unauthorized access.');
            navigate('/');
          }
          setDisplayName(tokenResult.claims?.name);
          setRoles(parsedUserRoles);
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(data) {
              setRoles(JSON.parse(data.claims?.roles));
              setDisplayName(data.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    getSupplierList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // On load function
  function generatePoId(dateToday) {
    const formattedDate = `${dateToday.day}${dateToday.monthShort.toLocaleUpperCase()}${dateToday.year}`;
    return `PO-${formattedDate}-${short.generate()}`;
  }

  async function getSupplierList() {
    const supplierResult = await searchSupplierDocument("supplierName",'','');
    setSupplierList(supplierResult.docs);
  }

  // Input Listeners
  function supplierNameListener(supplierName) {
    setSupplierName(supplierName);
  }
  function dateTransactionListener(event) {
    setDateTransaction(event.target.value);
    const formatDate = DateTime.fromFormat(event.target.value, 'yyyy-MM-dd');
    setPurchaseOrderId(generatePoId(formatDate));
  }
  function commentsListener(event) {
    setComments(event.target.value);
  }
  function currencyListener(event) {
    setCurrency(event.target.value);
  }
  function freightAmountListener(event) {
    // let formattedNumber;
    // if (event.target.value.length > 3) {
    //   const cleanInput = event.target.value.replace(',','');
    //   formattedNumber = Number(cleanInput).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    // } else {
    //   formattedNumber = event.target.value;
    // }
    // setFreightAmount(formattedNumber);
    setFreightAmount(event.target.value);
  }
  function proformaInvoiceImgUrlListener(event) {
    setProformaInvoiceFileName(event.target.value);
    setProformaInvoiceImgUrl(event.target.files[0]);
  }
  function commercialInvoiceImgUrlListener(event) {
    setCommercialInvoiceFileName(event.target.value);
    setCommercialInvoiceImgUrl(event.target.files[0]);
  }
  function itemIdListener(itemId, index) {
    const tempItemsArray = [].concat(items);
    const tempItem = tempItemsArray[index];
    const newItem = {...tempItem, itemId: itemId};
    tempItemsArray[index] = newItem;
    setItems(tempItemsArray);
  }
  function quantityListener(quantity, index) {
    const tempItemsArray = [].concat(items);
    const tempItem = tempItemsArray[index];
    const newItem = {...tempItem, quantity: quantity};
    tempItemsArray[index] = newItem;
    setItems(tempItemsArray);

    let totalItemCount = 0;
    for (const item of tempItemsArray) {
      totalItemCount += parseInt(item.quantity);
    }
    setTotalCount(totalItemCount);
  }
  function purchasePriceListener(purchasePrice, index) {
    const tempItemsArray = [].concat(items);
    const tempItem = tempItemsArray[index];
    const newItem = {...tempItem, purchasePrice: purchasePrice};
    tempItemsArray[index] = newItem;
    setItems(tempItemsArray);
  }

  async function createPurchaseOrder(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }
    if (!validateDropDowns()) {
      return;
    }
    const purchaseOrderData = {
      purchaseOrderId: purchaseOrderId,
      status: PoStatusEnum.OPEN,
      supplierName: supplierName,
      currency: currency,
      freightAmount: freightAmount,
      dateTransaction: dateTransaction,
      totalCount: totalCount,
      comments: comments,
    };
    const purchaseOrderItemsData = [];
    for (const i of items) {
      const item = {
        purchaseOrderId: purchaseOrderId,
        supplierName: supplierName,
        brand: i.brand ?? '',
        division: i.division ?? '',
        itemId: i.itemId,
        itemDescription: i.itemDescription,
        itemType: i.itemType ?? '',
        itemSubType: i.itemSubType ?? '',
        quantity: i.quantity,
        purchasePrice: i.purchasePrice ?? '',
        sellingPrice1: i.sellingPrice1 ?? '',
        sellingPrice2: i.sellingPrice2 ?? '',
        sellingPrice3: i.sellingPrice3 ?? '',
        sellingPrice4: i.sellingPrice4 ?? '',
        sellingPrice5: i.sellingPrice5 ?? '',
      }
      purchaseOrderItemsData.push(item);
    }
    setLoaderState(true);
    try {
      if (proformaInvoiceImgUrl) {
        const imgRef = await uploadToFirebaseStorage(proformaInvoiceImgUrl);
        const imgHttpUrl = await getDownloadURL(imgRef);
        setProformaInvoiceHttpUrl(imgHttpUrl);
        purchaseOrderData.proformaInvoiceImgUrl = imgHttpUrl;
      }
      if (commercialInvoiceImgUrl) {
        const imgRef = await uploadToFirebaseStorage(commercialInvoiceImgUrl);
        const imgHttpUrl = await getDownloadURL(imgRef);
        setCommercialInvoiceHttpUrl(imgHttpUrl);
        purchaseOrderData.commercialInvoiceImgUrl = imgHttpUrl;
      }
      console.log(await insertPurchaseOrderDocument(purchaseOrderData, displayName));
      for (const item of purchaseOrderItemsData) {
        console.log(await insertPurchaseOrderItemsDocument(item, displayName));
      }
    } catch (error) {
      await deleteFirebaseStorageFile(proformaInvoiceImgUrl);
      await deleteFirebaseStorageFile(commercialInvoiceImgUrl);
      await deletePurchaseOrderDocument(purchaseOrderId);
      // const docsSnapshot = await purchaseOrderItemsRef.where('purchaseOrderId','==',purchaseOrderId).get();
      const docsSnapshot = await getPurchasePrderItemsDocument(purchaseOrderId);
      docsSnapshot.forEach(async (doc) => {
        if (doc) {
          await deletePurchaseOrderItemsDocument(doc.docId);
        }
      });
    }
    setLoaderState(false);
    setSubmitSuccess(true);
    setValidated(false);
  }

  // upload Images
  async function uploadToFirebaseStorage(file) {
    const storageRef = ref(storage, `/purchase-order/${purchaseOrderId}/${file.name}`);
    await uploadBytes(storageRef, file).then(async (snapshot) => {});
    return storageRef;
  }

  // delete Images
  async function deleteFirebaseStorageFile(file) {
    const storageRef = ref(storage, `/purchase-order/${purchaseOrderId}/${file.name}`);
    await deleteObject(storageRef, file).then(async (snapshot) => {});
    return storageRef;
  }

  function removeItemHandler(index) {
    let tempItemsArray = [].concat(items);
    // Do not delete first item. Just blank out the date
    if (index === 0 && tempItemsArray.length === 0) {
      tempItemsArray = [''];
    } else {
      tempItemsArray.splice(index,1);
    }
    setItems(tempItemsArray);
    console.log(tempItemsArray);
  }

  function addItemConfirmation(selectedItem) {
    const tempItemsArray = [].concat(items);
    if (tempItemsArray.length > 1
        && tempItemsArray[tempItemsArray.length -1] === '') {
      alert("Please don't leave a blank item before adding a new one.")
      return;
    }
    tempItemsArray.push(
      {
        itemId: selectedItem.itemId,
        itemDescription: selectedItem.itemDescription,
        quantity: 0,
        brand: selectedItem.brand,
        division: selectedItem.division,
        itemType: selectedItem.itemType,
        itemSubType: selectedItem.itemSubType,
        purchasePrice: selectedItem.purchasePrice,
        sellingPrice1: selectedItem.sellingPrice1,
        sellingPrice2: selectedItem.sellingPrice2,
        sellingPrice3: selectedItem.sellingPrice3,
        sellingPrice4: selectedItem.sellingPrice4,
        sellingPrice5: selectedItem.sellingPrice5,
      }
    );
    setItems(tempItemsArray);
  }

  function validateDropDowns() {
    if (!supplierName) {
      return false;
    }
    if (items.length < 1) {
      alert("Cannot create Purchase order with no Items.")
      return false;
    }
    for (const item of items) {
        if (item.itemId === '') {
          alert("Cannot create Purchase order with blank Items.")
          return false;
        }
    }
    return true
  }

  function resetInputFields() {
    setPurchaseOrderId('');
    setSupplierName('');
    setDateTransaction('');
    setComments('');
    setCurrency('');
    setFreightAmount('');
    setProformaInvoiceImgUrl('');
    setProformaInvoiceFileName('');
    setProformaInvoiceHttpUrl('');
    setCommercialInvoiceImgUrl('');
    setCommercialInvoiceFileName('');
    setCommercialInvoiceHttpUrl('');
    setItems([{itemId: '', quantity: '', purchasePrice: ''}]);
    setSubmitSuccess(false);
    setPurchaseOrderId(generatePoId(DateTime.local()));
    setDateTransaction(DateTime.local().toFormat('yyyy-MM-dd').toString());
  }

  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayName}
        roles={roles}
      />
      <center><h3 style={{marginTop:15}}>Create Purchase Order</h3></center>
        { submitSuccess
          ? <center>
              <div className={classes.mainInputContainer}>
                <h3>Purchase Order Successfully Created.</h3>
                Purchase Order ID: {purchaseOrderId}
                { proformaInvoiceHttpUrl
                  ? <ImagePopUpModal
                    key={proformaInvoiceHttpUrl}
                    imgUrlIcon={proformaInvoiceHttpUrl}
                    imgUrl={proformaInvoiceHttpUrl}
                    />
                  : null
                }
                &nbsp;
                { commercialInvoiceHttpUrl
                  ? <ImagePopUpModal
                    key={commercialInvoiceHttpUrl}
                    imgUrlIcon={commercialInvoiceHttpUrl}
                    imgUrl={commercialInvoiceHttpUrl}
                    />
                  : null
                }
                <br></br><br></br>
                <Button variant="primary" onClick={() => {navigate(`/podetails/${purchaseOrderId}`)}}>View PO Details</Button>
                &nbsp;
                <Button variant="primary" onClick={resetInputFields}>Create Another PO</Button>
                &nbsp;
                <Button variant="primary" onClick={() => {window.location.reload(false)}}>Exit</Button>
              </div>
            </center>
          : <div className={classes.mainInputContainer}>
            <Form noValidate validated={validated} onSubmit={createPurchaseOrder}>
              <Form.Group className="mb-3" controlId="inputPurchaseOrder">
                <Form.Label column="lg">Purchase Order ID</Form.Label>
                <Form.Control type="text" disabled={true} value={purchaseOrderId} />
                <Form.Label column="lg">Details</Form.Label>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="supplier-name">Supplier Name</InputGroup.Text>
                <Dropdown
                  id="dropdown-button"
                  as={Form.Control}
                  value={supplierName}
                >
                  <Dropdown.Toggle variant="primary">
                    {supplierName || 'Select an option'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {
                      supplierList.map((val) => (
                        <Dropdown.Item
                          key={val.supplierName}
                          onClick={() => {supplierNameListener(val.supplierName)}}>
                            {val.supplierName}
                        </Dropdown.Item>
                      ))
                    }
                  </Dropdown.Menu>
                </Dropdown>
                <CustomInputValidation
                  validated={validated}
                  required={true}
                  evaluatedValue={supplierName}
                  label="Supplier Name"
                />
                </InputGroup>
                <CustomFeedbackValidation
                  validated={validated}
                  evaluatedValue={supplierName}
                  label="Supplier Name"
                />
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="transaction-date">Transaction Date</InputGroup.Text>
                <Form.Control
                  type="date"
                  placeholder="Transaction Date"
                  value={dateTransaction}
                  inputMode='none'
                  onChange={dateTransactionListener}
                  disabled={true}
                />
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="freight-amount" hidden={true}>Freight Amount</InputGroup.Text>
                <Form.Control
                  type="currency"
                  placeholder="amount"
                  value={freightAmount}
                  hidden={true}
                  onChange={freightAmountListener}
                />
                <InputGroup.Text id="currency" hidden={true}>Currency</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="php"
                  value={currency}
                  hidden={true}
                  onChange={currencyListener}
                />
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="comments">Comments</InputGroup.Text>
                <Form.Control
                  as="textarea"
                  aria-label="With textarea"
                  placeholder="comments (optional)"
                  value={comments}
                  onChange={commentsListener}
                />
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="po-image">Proforma Invoice</InputGroup.Text>
                <Form.Control
                  type="file"
                  value={proformaInvoiceFileName}
                  onChange={proformaInvoiceImgUrlListener}
                />
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="po-image">Commercial Invoice</InputGroup.Text>
                <Form.Control
                  type="file"
                  value={commercialInvoiceFileName}
                  onChange={commercialInvoiceImgUrlListener}
                />
                </InputGroup>
                {
                  items.map((val, index) => (
                    <div key={`items-${index}}`}
                      style={{border:"1px solid #1C6EA4",
                              borderRadius: "20px",
                              margin: "5px",
                              padding: "5px"}}
                      className={classes.inputBoxRadio}>
                      Item {index+1}:
                      &nbsp;
                      <Button
                        variant="danger"
                        onClick={() =>{removeItemHandler(index)}}
                        size="sm"
                        style={{marginBottom:5}}>
                          delete item
                      </Button>
                      <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id={`itemId-${index}`}>Item Id</InputGroup.Text>
                      <Form.Control
                        type="text"
                        disabled={true}
                        placeholder="Item Id"
                        value={items[index].itemId}
                        onChange={(event) => {itemIdListener(event.target.value, index)}}
                      />
                      </InputGroup>
                      <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id={`quantity-${index}`}>Quantity</InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        pattern="^[1-9][0-9]*$"
                        placeholder="0"
                        value={items[index].quantity}
                        onChange={(event) => {quantityListener(event.target.value, index)}}
                      />
                      <Form.Control.Feedback type="invalid">
                        Quantity should be a number and not be 0 or blank.
                      </Form.Control.Feedback>
                      <InputGroup.Text id={`purchasePrice-${index}`}
                        hidden={true}
                      >Purchase Price
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="0"
                        value={items[index].purchasePrice}
                        onChange={(event) => {purchasePriceListener(event.target.value, index)}}
                        hidden={true}
                      />
                      </InputGroup>
                    </div>
                  ))
                }
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="po-image">Total Item Count</InputGroup.Text>
                <Form.Control
                  type="text"
                  value={totalCount}
                  disabled={true}
                />
                </InputGroup>
                <PoItemAddModal
                  supplierName={supplierName}
                  onConfirmation={addItemConfirmation}
                />
                <br></br>
                <center><Button type="submit">Create Purchase Order</Button></center>
              </Form.Group>
            </Form>
            </div>
      }
    </div>
    </CustomLoader>
  );
}

export default PoCreate;
