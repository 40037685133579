import React, { useState, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { listDocuments } from "../database/standard/get";


const SelectOnlyDropdown = (props) => {

  const dbName = props.dbName;
  const elementObjectKey = props.elementObjectKey;
  const [elementList, setElementList] = useState([]);
  const [selectedElement, setSelectedElement] = useState(props.selectedElement);

  useEffect(() => {
    getElements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.selectedElement]);

  async function getElements() {
    console.log("CALLED");
    const elementList = await listDocuments(dbName, elementObjectKey, elementObjectKey, "asc");
    elementList.push({[elementObjectKey]: "BLANK"});
    setElementList(elementList);
  }

  function elementDropDownListener(elementInput) {
    setSelectedElement(elementInput);
    props.onSelectedElement(elementInput);
  }


  return (
    <React.Fragment>
      <Dropdown
        id="dropdown-button"
        as={Form.Control}
        value={selectedElement}
        >
      <Dropdown.Toggle variant="primary">
        {selectedElement || 'Select an option'}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {
          elementList.map((val) => (
            <Dropdown.Item
              key={val[elementObjectKey]}
              onClick={() => {elementDropDownListener(val[elementObjectKey])}}>
                {val[elementObjectKey]}
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
}

export default SelectOnlyDropdown

