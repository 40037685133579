import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebaseConfig";


async function DownloadFileFromStorage(file) {
  const fileRef = ref(storage, file);
  const downloadURL = await getDownloadURL(fileRef);
  window.open(downloadURL);
}

export default DownloadFileFromStorage;
