import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ListGroup from 'react-bootstrap/ListGroup';
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./SupplierSearch.module.css";
import { firebaseObserver } from '../../firebaseConfig';
import { useNavigate } from "react-router-dom";
import { searchSupplierDocument } from "../../components/database/supplier/searchSupplier";
import { nextSupplierDocument } from "../../components/database/supplier/nextSupplier";
import { prevSupplierDocument } from "../../components/database/supplier/prevSupplier";
import { useAuthContext } from "../../components/context/useAuthContext";


const SupplierSearch = () => {

  // PO List
  const [suppliers, setSuppliers] = useState([]);
  // First Document in query pagination
  const [firstVisible, setFirstVisible] = useState();
  // Last Document in query pagination
  const [lastVisible, setLastVisible] = useState();
  // Previous button switch
  const [disablePrevButton, setDisablePrevButton] = useState(true);
  // Next button switch
  const [disableNextButton, setDisableNextButton] = useState(true);
  // Search Value
  const [searchInput, setSearchInput] = useState('');
  // Search option
  const [searchOption, setSearchOption] = useState('Supplier Name');

  // User Auth Context
  const { user } = useAuthContext();
  // User Display Name
  const [displayName, setDisplayName] = useState(user?.displayName);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
      console.log(user.displayName);
    });
    searchSupplier(searchOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[displayName]);

  function searchInputListener(event) {
    setSearchInput(event.target.value);
  }

  function searchOptionListener(searchOption) {
    setSearchOption(searchOption);
    console.log(searchOption);
  }

  function enterKeySearch(event) {
    if (event.key === 'Enter') {
      searchSupplier(searchOption);
    }
  }

  function mapSearchOption(searchOption) {
    let mappedSearchOption;
    switch(searchOption) {
      case 'Supplier Name':
        mappedSearchOption = 'supplierName';
        break;
      case 'Contact Person':
        mappedSearchOption = 'contactPerson';
        break;
      default:
        mappedSearchOption = null;
    }
    return mappedSearchOption;
  }

  async function searchSupplier(searchType) {
    console.log('USE EFFECT EXECUTED');
    setLoaderState(true);
    const searchOption = mapSearchOption(searchType);
    const supplierResult = await searchSupplierDocument(searchOption, searchInput, 5);
    setSuppliers(supplierResult.docs);
    setFirstVisible(supplierResult.firstVisibleDocument);
    setLastVisible(supplierResult.lastVisibleDocument);
    if (supplierResult.isNextAvailable) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
    setDisablePrevButton(true);
    setLoaderState(false);
  }

  async function getNext(searchType) {
    setLoaderState(true);
    const searchOption = mapSearchOption(searchType);
    const supplierResult = await nextSupplierDocument(searchOption, searchInput, lastVisible, 5);
    setSuppliers(supplierResult.docs);
    setFirstVisible(supplierResult.firstVisibleDocument);
    setLastVisible(supplierResult.lastVisibleDocument);
    if (supplierResult.isNextAvailable) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
    setDisablePrevButton(false);
    setLoaderState(false);
  }

  async function getPrev(searchType) {
    setLoaderState(true);
    const searchOption = mapSearchOption(searchType);
    const supplierResult = await prevSupplierDocument(searchOption, searchInput, firstVisible, 5);
    setSuppliers(supplierResult.docs);
    setFirstVisible(supplierResult.firstVisibleDocument);
    setLastVisible(supplierResult.lastVisibleDocument);
    if (supplierResult.isPrevAvailable) {
      setDisablePrevButton(false);
    } else {
      setDisablePrevButton(true);
    }
    setDisableNextButton(false);
    setLoaderState(false);
  }

  function supplierDetailsLink(supplierName) {
    navigate(`/supplierdetails/${supplierName}`);
  }

  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center><h3 style={{marginTop:15}}>Search Supplier</h3></center>
      <div className={classes.mainInputContainer}>
        <center>
          <input type='searchInput' value={searchInput} id='searchInput' style={{marginBottom:10}}
            onChange={searchInputListener}
            onKeyDown={enterKeySearch}/>
          &nbsp;
          <Button variant="primary" onClick={() => searchSupplier(searchOption)}>Search</Button>
          <DropdownButton id="search-options-dropdown-button" title={searchOption}>
            <Dropdown.Item onClick={() => {searchOptionListener('Supplier Name')}}>Supplier Name</Dropdown.Item>
            <Dropdown.Item onClick={() => {searchOptionListener('Contact Person')}}>Contact Person</Dropdown.Item>
          </DropdownButton>
        </center>
        &nbsp;
        <ListGroup>
        {
          suppliers.map((val, index) => (
            <ListGroup.Item key={val.supplierName} action onClick={() => {supplierDetailsLink(val.supplierName)}}>
              <div className="ms-2 me-auto">
                <b>Supplier Name</b>: {val.supplierName}
                &nbsp; <b>Contact Person:</b> {val.contactPerson}
              </div>
            </ListGroup.Item>
          ))
        }
        </ListGroup>
        <br></br>
        <center>
          { disablePrevButton
            ? null
            : <Button variant="primary" onClick={() => getPrev(searchOption)}>Previous</Button>
          }
          &nbsp;&nbsp;
          { disableNextButton
            ? null
            : <Button variant="primary" onClick={() => getNext(searchOption)}>Next</Button>
          }
        </center>
      </div>
    </div>
    </CustomLoader>
  );
}

export default SupplierSearch;
