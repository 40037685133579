import { useState, useEffect } from "react";
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./JobDetails.module.css";
import { firebaseObserver } from '../../firebaseConfig';
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { getDocument } from "../../components/database/standard/get";


const JobDetails = () => {

  const params = useParams();

  const jobId = params.jobId;
  const [status, setStatus] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [type, setType] = useState('');
  const [loadCount, setLoadCount] = useState(0);
  const [filePath, setFilePath] = useState('');
  const [startedAt, setStartedAt] = useState('');
  const [endedAt, setEndedAt] = useState('');
  const [preparedData, setPreparedData] = useState({});
  const [triggeredBy, setTriggeredBy] = useState('');

  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
    });
    searchJob();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function searchJob() {
    const jobResult = await getDocument("jobs", "jobId", jobId);
    populateFields(jobResult.docs[0].data());
  }

  function populateFields(jobDocument) {
    setStatus(jobDocument.status);
    setStatusMessage(jobDocument.statusMessage);
    setType(jobDocument.type);
    setFilePath(jobDocument.filePath);
    setLoadCount(jobDocument.loadCount ?? 0);
    const startedAt = jobDocument.startedAt.toDate().toLocaleString();
    setStartedAt(startedAt);
    const endedAt = jobDocument.endedAt?.toDate().toLocaleString() ?? jobDocument.dateModified?.toDate().toLocaleString();
    setEndedAt(endedAt);
    setPreparedData(jobDocument.preparedData);
    setTriggeredBy(jobDocument.triggeredBy);
  }

  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center><h3 style={{marginTop:15}}>Job Details</h3></center>
      <div className={classes.mainInputContainer}>
        <center>
          <Form>
          <Form.Group className="mb-3" controlId="inputItem">
          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="jobId">Job Id</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="jobId"
              value={jobId}
              disabled
            />
          </InputGroup>
          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="status">Job Status</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="status"
              value={status}
              disabled
            />
          </InputGroup>
          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="status">Job Status Message</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="status message"
              value={statusMessage}
              disabled
            />
          </InputGroup>
          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="type">Job Type</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="type"
              value={type}
              disabled
            />
          </InputGroup>
          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="loadCount">Load Count</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="loadCount"
              value={loadCount}
              disabled
            />
          </InputGroup>
          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="filePath">File</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="filePath"
              value={filePath}
              disabled
            />
          </InputGroup>
          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="startedAt">Started At</InputGroup.Text>
            <Form.Control
              type="text"
              value={startedAt}
              disabled
            />
          </InputGroup>
          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="endedAt">Ended At</InputGroup.Text>
            <Form.Control
              type="text"
              value={endedAt}
              disabled
            />
          </InputGroup>
          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="triggeredBy">Triggered By</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="triggeredBy"
              value={triggeredBy}
              disabled
            />
          </InputGroup>
          </Form.Group>
          </Form>
        </center>
      </div>
    </div>
    </CustomLoader>
  );
}

export default JobDetails;
