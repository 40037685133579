import * as React from "react";
import { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./ItemMasterMassInput.module.css";
import { firebaseObserver, storage } from '../../firebaseConfig';
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import InsertDataDropdownModal from "../../components/functional/InsertDataDropdownModal";
import { insertDocument } from "../../components/database/standard/insert";
import CustomFeedbackValidation from "../../components/ui-common/CustomFeedbackValidation";
import { ref, uploadBytes } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import short from "short-uuid";
import { DateTime } from "luxon";
import { JobStatus, JobType } from "../../models/jobs";
import { updateDocument } from "../../components/database/standard/update";
import DownloadFileFromStorage from "../../components/functional/DownloadFileFromStorage";
import { ItemMasterStatusEnum } from "../../models/status";


const ItemMasterMassInput = () => {

  const [brand, setBrand] = useState('');
  const [division, setDivision] = useState('');
  const [itemType, setItemType] = useState('');
  const [itemSubType, setItemSubType] = useState('');
  const [fileUpload, setFileUpload] = useState('');
  const [fileUploadFileName, setFileUploadFileName] = useState('');
  const [fileName, setFileName] = useState('');
  const filePath = '/data-loading/item-master/';
  const jobId = generateJobId(DateTime.local());

  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [loaderState, setLoaderState] = useState(false);
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[displayName]);

  // On load function
  function generateJobId(dateToday) {
    const formattedDate = `${dateToday.day}${dateToday.monthShort.toLocaleUpperCase()}${dateToday.year}`;
    return `JOB-${formattedDate}-${short.generate()}`;
  }

  // Input Listeners
  function selectedBrand(brand) {
    setBrand(brand);
  }
  function selectedDivision(division) {
    setDivision(division);
  }
  function selectedItemType(itemType) {
    setItemType(itemType);
  }
  function selectedItemSubType(itemSubType) {
    setItemSubType(itemSubType);
  }
  function fileUploadlListener(event) {
    const file = event.target.files[0];
    setFileUploadFileName(event.target.value);
    const fileNameTemp = file.name.split(".");
    const fileExtension = fileNameTemp[fileNameTemp.length - 1];
    setFileName(`${jobId}.${fileExtension}`);
    setFileUpload(event.target.files[0]);
  }

  async function uploadFile(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }
    if (!validateDropDowns()) {
      return;
    }
    let preparedData = prepareData();
    if (!preparedData) {
      return;
    }
    setLoaderState(true);
    const jobData = {
      jobId: fileName.split(".")[0],
      type: JobType.ITEM_MASTER_LOADING,
      preparedData: preparedData,
      status: JobStatus.SUBMITTED,
      statusMessage: "Job Submitted",
      filePath: `${filePath}${fileName}`,
      startedAt: Timestamp.now(),
      triggeredBy: displayName,
    };
    try {
      if (fileUpload) {
        await insertDocument("jobs", "jobId", jobData, true, displayName);
      }
    } catch (error) {
      alert("Unable to upload the file");
      setLoaderState(false);
      return;
    }
    try {
        await uploadToFirebaseStorage(fileUpload);
    } catch (error) {
      jobData.status = JobStatus.ERROR;
      jobData.statusMessage = "Error uploading the file.";
      await updateDocument("jobs", jobId, jobData, displayName);
      setLoaderState(false);
    }
    setLoaderState(false);
    setSubmitSuccess(true);
  }

  // upload Images
  async function uploadToFirebaseStorage(file) {
    const storageRef = ref(storage, `${filePath}${fileName}`);
    await uploadBytes(storageRef, file).then(async (snapshot) => {});
    return storageRef;
  }

  function prepareData() {
    if (!brand || !division || !itemType || !itemSubType) {
      alert("Missing Required Fields");
      return null;
    }
    return {
      brand: brand,
      division: division,
      itemType: itemType,
      itemSubType: itemSubType,
      status: ItemMasterStatusEnum.AVAILABLE
    };
  }

  function validateDropDowns() {
    if (!brand || !division || !itemType || !itemSubType) {
      return false;
    }
    return true;
  }
  async function handleDownload() {
    await DownloadFileFromStorage("/data-loading/item-master-template.xlsx");
  }

  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center><h3 style={{marginTop:15}}>Mass Load Items</h3></center>
      { submitSuccess
        ? <center>
            <div className={classes.mainInputContainer}>
              <h3>Items Have Been Submitted For Processing.</h3>
              <Button variant="primary" onClick={() => {navigate(`/jobdetails/${fileName.split(".")[0]}`)}}>View Job Details</Button>
              &nbsp;
              <Button variant="primary" onClick={() => {window.location.reload(false)}}>Exit</Button>
            </div>
          </center>
        : <div className={classes.mainInputContainer}>
          <Form noValidate validated={validated} onSubmit={uploadFile}>
            <Form.Group className="mb-3" controlId="inputItem">
              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="brand-name">Brand</InputGroup.Text>
              <InsertDataDropdownModal
                validated={validated}
                selectedElement={brand}
                onSelectedElement={selectedBrand}
                displayName={displayName}
                elementObjectKey="brand"
                dbName="brand"
                label="Brand"
                required={true}
              />
              </InputGroup>
              <CustomFeedbackValidation
                validated={validated}
                evaluatedValue={brand}
                label="Brand"
              />
              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="division">Division</InputGroup.Text>
              <InsertDataDropdownModal
                validated={validated}
                selectedElement={division}
                onSelectedElement={selectedDivision}
                displayName={displayName}
                elementObjectKey="division"
                dbName="division"
                label="Division"
                required={true}
              />
              </InputGroup>
              <CustomFeedbackValidation
                validated={validated}
                evaluatedValue={division}
                label="Division"
              />
              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="item-types">Item Type</InputGroup.Text>
              <InsertDataDropdownModal
                validated={validated}
                selectedElement={itemType}
                onSelectedElement={selectedItemType}
                displayName={displayName}
                elementObjectKey="itemType"
                dbName="item-type"
                label="Item Type"
                required={true}
              />
              </InputGroup>
              <CustomFeedbackValidation
                validated={validated}
                evaluatedValue={itemType}
                label="Item Type"
              />
              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="item-sub-type">Item Sub Type</InputGroup.Text>
              <InsertDataDropdownModal
                validated={validated}
                selectedElement={itemSubType}
                onSelectedElement={selectedItemSubType}
                displayName={displayName}
                elementObjectKey="itemSubType"
                dbName="item-sub-type"
                label="Item Sub Type"
                required={true}
              />
              </InputGroup>
              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="po-image">File Upload</InputGroup.Text>
              <Form.Control
                type="file"
                value={fileUploadFileName}
                onChange={fileUploadlListener}
                required={true}
              />
              <Form.Control.Feedback type="invalid">
                Excel file is required.
              </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <center>
              <Button variant="primary" onClick={handleDownload}>Download Template</Button>
              &nbsp;
              <Button type="submit">Upload File</Button>
            </center>
          </Form>
          </div>
      }
    </div>
    </CustomLoader>
  );
}

export default ItemMasterMassInput;
