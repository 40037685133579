import { db } from '../../../firebaseConfig';
import { collection, doc, setDoc, Timestamp } from 'firebase/firestore/lite';


const purchaseOrderRef = collection(db, "purchase-order");
const purchaseOrderItemsRef = collection(db, "purchase-order-items");

export const insertPurchaseOrderDocument = async (insertData, contextUser) => {
  insertData.modifiedBy = contextUser;
  insertData.dateModified = Timestamp.now();
  await setDoc(doc(purchaseOrderRef, insertData.purchaseOrderId), insertData);
  return true;
};

export const insertPurchaseOrderItemsDocument = async (insertData, contextUser) => {
  insertData.modifiedBy = contextUser;
  insertData.dateModified = Timestamp.now();
  await setDoc(doc(purchaseOrderItemsRef), insertData);
  return true;
};
