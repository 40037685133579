import { db } from '../../../firebaseConfig';
import { doc, collection, updateDoc, Timestamp } from 'firebase/firestore/lite';

export const updateSupplierDocument = async (documentId, updateData, contextUser) => {
  const collectionRef = collection(db, "supplier");
  updateData.modifiedBy = contextUser;
  updateData.dateModified = Timestamp.now();
  await updateDoc(doc(collectionRef, documentId), updateData);
  return true;
};
