export const JobType = {
  ITEM_MASTER_LOADING: "ITEM-MASTER-LOADING",
  RECEIVING_REGISTER_LOADING: "RECEIVING-REGISTER-LOADING",
}

export const JobTypeList = [
  "ITEM-MASTER-LOADING",
  "RECEIVING-REGISTER-LOADING",
  "ALL",
]

export const JobStatus = {
  SUBMITTED: "SUBMITTED",
  VALIDATING: "VALIDATING",
  VALIDATION_COMPLETE: "VALIDATION_COMPLETE",
  VALIDATION_ERROR: "VALIDATION_ERROR",
  LOADING: "LOADING",
  LOADING_COMPLETE: "LOADING_COMPLETE",
  LOADING_ERROR: "LOADING_ERROR",
  ERROR: "ERROR"
}

export const JobStatusList = [
  "SUBMITTED",
  "VALIDATING",
  "VALIDATION_COMPLETE",
  "VALIDATION_ERROR",
  "LOADING",
  "LOADING_COMPLETE",
  "LOADING_ERROR",
  "ERROR",
  "ALL",
]
