import * as React from "react";
import { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./SupplierInput.module.css";
import { firebaseObserver } from '../../firebaseConfig';
// import { object, string, bool, number, InferType } from 'yup';
import { useNavigate } from "react-router-dom";
import { insertSupplierDocument } from "../../components/database/supplier/insertSupplier";
import { useAuthContext } from "../../components/context/useAuthContext";

// const schema = object().shape({
//     firstName: string().required(),
//     lastName: string().required(),
//     username: string().required(),
//     city: string().required(),
//     state: string().required(),
//     zip: string().required(),
//     terms:  bool().required().oneOf([true], 'Terms must be accepted'),
//   });

const SupplierInput = () => {

  // supplierName input form listener
  const [supplierName, setSupplierName] = useState('');
  // address input form listener
  const [address, setAddress] = useState('');
  // contactPerson input form listener
  const [contactPerson, setContactPerson] = useState('');
  // cellphoneNumber input form listener
  const [cellphoneNumber, setCellphoneNumber] = useState('');
  // emailAddress input form listener
  const [emailAddress, setEmailAddress] = useState('');

  // User Auth Context
  const { user } = useAuthContext();
  // User Display Name
  const [displayName, setDisplayName] = useState(user?.displayName);
  // Create user Result
  const [submitSuccess, setSubmitSuccess] = useState(false);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
      console.log(user.displayName);
    });
    return () => { firebaseObserver.unsubscribe('authStateChanged'); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[displayName]);

  // Input Listeners
  function supplierNameListener(event) {
    setSupplierName(event.target.value);
  }
  function addressListener(event) {
    setAddress(event.target.value);
  }
  function contactPersonListener(event) {
    setContactPerson(event.target.value);
  }
  function cellphoneNumberListener(event) {
    setCellphoneNumber(event.target.value);
  }
  function emailAddressListener(event) {
    setEmailAddress(event.target.value);
  }


  async function createSupplier() {
    console.log(displayName);
    if (!validateInputs()) {
      return;
    }
    const supplierData = {
      supplierName: supplierName,
      address: address,
      contactPerson: contactPerson,
      cellphoneNumber: cellphoneNumber,
      emailAddress: emailAddress,
    };
    setLoaderState(true);
    const insertResult = await insertSupplierDocument(supplierData, displayName);
    setLoaderState(false);
    setSubmitSuccess(insertResult);
  }

  function validateInputs() {
    if (!supplierName) {
      alert("Please enter a supplier name.")
      return false;
    }
    return true
  }

  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center><h3 style={{marginTop:15}}>Create Supplier</h3></center>
        { submitSuccess
          ? <center>
              <div className={classes.mainInputContainer}>
                <h3>Supplier Successfully Created.</h3>
                Supplier ID: {supplierName}
                <br></br><br></br>
                <Button variant="primary" onClick={() => {navigate(`/supplierdetails/${supplierName}`)}}>View Supplier Details</Button>
                &nbsp;
                <Button variant="primary" onClick={() => {window.location.reload(false)}}>Create Another Supplier</Button>
                &nbsp;
                <Button variant="primary" onClick={() => {navigate('/')}}>Exit</Button>
              </div>
            </center>
          : <div className={classes.mainInputContainer}>
            <Form>
              <Form.Group className="mb-3" controlId="inputSupplier">
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="supplier-name">Supplier Name</InputGroup.Text>
                <Form.Control
                  type="text" placeholder="Supplier Name" value={supplierName}
                  onChange={supplierNameListener}
                />
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="contact-person">Address</InputGroup.Text>
                <Form.Control
                  as="textarea" aria-label="With textarea" placeholder="Address" value={address}
                  onChange={addressListener}
                />
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="contact-person">Contact Person</InputGroup.Text>
                <Form.Control
                  type="text" placeholder="Contact Person" value={contactPerson}
                  onChange={contactPersonListener}
                />
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="cellphone-number">Cellphone Number</InputGroup.Text>
                <Form.Control
                  type="text" placeholder="Cellphone Number" value={cellphoneNumber}
                  onChange={cellphoneNumberListener}
                />
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="emailAddress">Email Address</InputGroup.Text>
                <Form.Control
                  type="text" placeholder="Email Address" value={emailAddress}
                  onChange={emailAddressListener}
                />
                </InputGroup>
              </Form.Group>
            </Form>
            <center><Button variant="primary" onClick={createSupplier}>Create Supplier</Button></center>
            </div>
      }
    </div>
    </CustomLoader>
  );
}

export default SupplierInput;
