import * as React from "react";
import MainHeader from "../../components/navigation/MainHeader";
import { useNavigate } from "react-router-dom";
import classes from "./UserProfile.module.css";
import { useEffect, useState } from "react";
import CustomLoader from "../../components/ui-common/CustomLoader";
import { db, auth, firebaseObserver } from "../../firebaseConfig";
import { collection, getDoc, doc } from 'firebase/firestore/lite';
import { sendPasswordResetEmail } from "firebase/auth"
import Button from "react-bootstrap/Button";
import { useAuthContext } from "../../components/context/useAuthContext";


const UserProfile = () => {

  // User Auth Context
  const { user } = useAuthContext();
  // User list state
  const [userPofile, setUserProfile] = useState();
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // DisplayName state
  const [displayName, setDisplayName] = useState(user?.displayName);

  // Start Firebase Auth Context Management
  /* eslint-disable */
  const navigate = useNavigate()
  useEffect(() => {
    // console.log(displayName);
    // console.log(user);
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
    });
    if (displayName) {
      getUser(displayName);
    }
    return () => { firebaseObserver.unsubscribe('authStateChanged'); }
  },[displayName]);
  /* eslint-enable */
  // End Firebase Auth Context Management

  async function getUser(displayName) {
    // Enable loader when request is being sent.
    setLoaderState(true);

    const employeesRef = collection(db, "employees");
    const documentRef = doc(employeesRef, displayName);
    const document = await getDoc(documentRef);
    setUserProfile(document.data());
    setLoaderState(false);
  }

  async function resetPassword(email) {
    setLoaderState(true);
    return sendPasswordResetEmail(auth, email).then((a) => {
      setLoaderState(false);
      alert(`Password reset email sent to ${email}.`);
    });
  }

  return (
    <CustomLoader
    isActive={loaderState}
    >
      <div className={classes.overlayWrapper}> <MainHeader />
        <center>
        <h4 style={{marginTop:20}}>User Profile</h4>
        <ul className={classes.viewBox}>
        { userPofile ?
          <label>
            <b>Display Name:</b> {userPofile.displayName} <br></br>
            <b>Email:</b> {userPofile.email} <br></br>
            <b>First Name:</b> {userPofile.firstName} <br></br>
            <b>Last Name:</b> {userPofile.lastName} <br></br>
          </label>
        : null }
        <br></br>
        <br></br>
        <Button variant='primary' onClick={() => resetPassword(userPofile.email)}>
          Reset My Account Password
        </Button>
        </ul>
        </center>
      </div>
    </CustomLoader>
  );
}

export default UserProfile;
