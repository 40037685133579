import { db } from '../../../firebaseConfig';
import { getDocs, collection, query, where } from 'firebase/firestore/lite';


const purchaseOrderRef = collection(db, "purchase-order");
const purchaseOrderItemsRef = collection(db, "purchase-order-items");

export const getPurchasePrderDocument = async (purchaseOrderId) => {
  const q = query(purchaseOrderRef,
    where('purchaseOrderId','==',purchaseOrderId));
  return getDocs(q);
};

export const getPurchasePrderItemsDocument = async (purchaseOrderId) => {
  const q = query(purchaseOrderItemsRef,
    where('purchaseOrderId','==',purchaseOrderId));
  const docsSnapshot = await getDocs(q);
  const tempItems = [];
  docsSnapshot.forEach((doc) => {
    if (doc) {
      tempItems.push({docId: doc.id, ...doc.data()});
    }
  });
  return tempItems;
};
