import { db } from '../../../firebaseConfig';
import { getDocs, collection, query, where, orderBy, startAfter, limit } from 'firebase/firestore/lite';

export const nextSupplierDocument = async (searchOption, searchInput, lastVisible, searchLimit) => {
  let q;
  let queryFilterStart;
  let queryFilterEnd;
  let isNextAvailable = false;
  const collectionRef = collection(db, "supplier");

  if (searchInput) {
    const searchInputEnd = searchInput.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
    queryFilterStart = where(searchOption,'>=',searchInput);
    queryFilterEnd = where(searchOption,'<',searchInputEnd);
    q = query(collectionRef,
      queryFilterStart,
      queryFilterEnd,
      orderBy(searchOption, "asc"),
      startAfter(lastVisible),
      limit(searchLimit));
  } else {
    queryFilterStart = orderBy(searchOption, "asc");
    q = query(collectionRef,
      queryFilterStart,
      startAfter(lastVisible),
      limit(searchLimit));
  }
  const docsSnapshot = await getDocs(q);
  const tempList = [];
  docsSnapshot.forEach((doc) => {
    if (doc) {
      tempList.push(doc.data());
    }
  });
  // Populate PO list state
  const parsedDocs = tempList;
  const firstVisibleDocument = docsSnapshot.docs[0];
  const lastVisibleDocument = docsSnapshot.docs[docsSnapshot.docs.length-1];

  let nextq;
  if (searchInput) {
    nextq = query(collectionRef,
      queryFilterStart,
      queryFilterEnd,
      orderBy(searchOption, "asc"),
      startAfter(lastVisibleDocument),
      limit(searchLimit));
  } else {
    nextq = query(collectionRef,
      queryFilterStart,
      startAfter(lastVisibleDocument),
      limit(searchLimit));
  }
  const docsSnapshotNextAhead = await getDocs(nextq);
  if (docsSnapshotNextAhead.docs.length < 1) {
    isNextAvailable = false;
  } else {
    isNextAvailable = true;
  }

  return ({
    docs: parsedDocs,
    firstVisibleDocument: firstVisibleDocument,
    lastVisibleDocument: lastVisibleDocument,
    isNextAvailable: isNextAvailable
  })
};
