import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { insertDocument } from "../database/standard/insert";
import { listDocuments } from "../database/standard/get";
import CustomInputValidation from "../ui-common/CustomInputValidation";


const InsertDataDropdownModal = (props) => {

  const label = props.label;
  const dbName = props.dbName;
  const elementObjectKey = props.elementObjectKey;
  const elementDescriptionObjectKey = `${props.elementObjectKey}Description`;
  const [element, setElement] = useState('');
  const [elementDescription, setElementDescription] = useState('');

  const [show, setShow] = useState(false);
  const [elementList, setElementList] = useState([]);
  const [selectedElement, setSelectedElement] = useState(props.selectedElement);
  const displayName = props.displayName;

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  useEffect(() => {
    getElements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.selectedElement]);

  async function getElements() {
    console.log("CALLED");
    const elementList = await listDocuments(dbName, elementObjectKey, elementObjectKey, "asc");
    elementList.push({[elementObjectKey]: `Create New ${label}`});
    setElementList(elementList);
  }

  function elementDropDownListener(elementInput) {
    if (elementInput === `Create New ${label}`) {
      setShow(true);
    } else {
      setSelectedElement(elementInput);
      props.onSelectedElement(elementInput);
      setShow(false);
    }
  }

  function elementListener(event) {
    setElement(event.target.value.toUpperCase());
  }

  function elementDescriptionListener(event) {
    setElementDescription(event.target.value);
  }

  async function createNewElement() {
    if (!validateInputs()) {
      alert(`${label} is required`);
      return;
    }
    const elementData = {
      [elementObjectKey]: element,
      [elementDescriptionObjectKey]: elementDescription
    };
    const insertSuccess = await insertDocument(dbName, elementObjectKey, elementData, false, displayName);
    if (!insertSuccess){
      return;
    }
    setElement(element);
    setSelectedElement(element);
    props.onSelectedElement(element);
    setShow(false);
  }

  function validateInputs() {
    if (!element) {
      return false;
    }
    return true;
  }


  return (
    <React.Fragment>
      <Dropdown
        id="dropdown-button"
        as={Form.Control}
        value={selectedElement}
        >
      <Dropdown.Toggle variant="primary">
        {selectedElement || 'Select an option'}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {
          elementList.map((val) => (
            <Dropdown.Item
              key={val[elementObjectKey]}
              onClick={() => {elementDropDownListener(val[elementObjectKey])}}>
                {val[elementObjectKey]}
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
      <CustomInputValidation
        validated={props.validated}
        required={props.required}
        evaluatedValue={props.selectedElement}
        label={props.label}
      />
      </Dropdown>
      <Modal
        size="m"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New {label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3" controlId="inputItem">
            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="element">{label}</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder={label}
              value={element}
              onChange={elementListener}
            />
            </InputGroup>
            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="itemTypeDescription">{label} Description</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder={label.concat(" Description")}
              value={elementDescription}
              onChange={elementDescriptionListener}
            />
            </InputGroup>
          </Form.Group>
          </Form>
          <center><Button variant="primary" onClick={createNewElement}>Create</Button></center>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default InsertDataDropdownModal

