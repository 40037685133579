import { db } from '../../../firebaseConfig';
import { getDocs, collection, query, where, orderBy, endBefore, limitToLast } from 'firebase/firestore/lite';

export const prevSupplierDocument = async (searchOption, searchInput, firstVisible, searchLimit) => {
  let q;
  let queryFilterStart;
  let queryFilterEnd;
  let isPrevAvailable = false;
  const collectionRef = collection(db, "supplier");

  if (searchInput) {
    const searchInputEnd = searchInput.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
    queryFilterStart = where(searchOption,'>=',searchInput);
    queryFilterEnd = where(searchOption,'<',searchInputEnd);
    q = query(collectionRef,
      queryFilterStart,
      queryFilterEnd,
      orderBy(searchOption, "asc"),
      endBefore(firstVisible),
      limitToLast(searchLimit));
  } else {
    queryFilterStart = orderBy(searchOption, "asc");
    q = query(collectionRef,
      queryFilterStart,
      endBefore(firstVisible),
      limitToLast(searchLimit));
  }
  const docsSnapshot = await getDocs(q);
  const tempList = [];
  docsSnapshot.forEach((doc) => {
    if (doc) {
      tempList.push(doc.data());
    }
  });
  // Populate PO list state
  const parsedDocs = tempList;
  const firstVisibleDocument = docsSnapshot.docs[0];
  const lastVisibleDocument = docsSnapshot.docs[docsSnapshot.docs.length-1];

  let prevq;
  if (searchInput) {
    prevq = query(collectionRef,
      queryFilterStart,
      queryFilterEnd,
      orderBy(searchOption, "asc"),
      endBefore(firstVisibleDocument),
      limitToLast(searchLimit));
  } else {
    prevq = query(collectionRef,
      queryFilterStart,
      endBefore(firstVisibleDocument),
      limitToLast(searchLimit));
  }
  const docsSnapshotPrevAhead = await getDocs(prevq);
  if (docsSnapshotPrevAhead.docs.length < 1) {
    isPrevAvailable = false;
  } else {
    isPrevAvailable = true;
  }

  return ({
    docs: parsedDocs,
    firstVisibleDocument: firstVisibleDocument,
    lastVisibleDocument: lastVisibleDocument,
    isPrevAvailable: isPrevAvailable
  })
};
