import { httpsCallable } from 'firebase/functions';
import Button from 'react-bootstrap/Button';
import { functions } from '../../firebaseConfig';

// This page should only be deployed once during initial app setup
//   it will forcefully set the first super admin user jimmycdo@yahoo.com
const FirstAdmin = () => {

async function setFirstAdmin() {
  const setUserAdmin = httpsCallable(functions, 'setUserAdmin');
  await setUserAdmin({
    email: "jimmycdo@yahoo.com",
    admin: true,
    superadmin: true
  })
  .then((response) => {
    if (response.data.message) {
      alert(response.data.message);
    } else {
      alert(response.data.errorInfo.message);
    }
  })
  .catch((error) => {
    alert(error);
  });
}

return (
  <center>
    <Button variant='primary' onClick={setFirstAdmin}>
      CLICK ME
    </Button>
  </center>
)

}

export default FirstAdmin;
