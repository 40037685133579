import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from 'firebase/firestore/lite';
import ReactObserver from 'react-event-observer';
import { getFunctions } from 'firebase/functions';

// Firebase config - DEV
export const firebaseConfig = {
  apiKey: "AIzaSyDcOojvCEmCxMUUgfPL2hj_DQDq27UIQ-c",
  authDomain: "dev-twi-erp.firebaseapp.com",
  projectId: "dev-twi-erp",
  storageBucket: "dev-twi-erp.appspot.com",
  messagingSenderId: "460641879600",
  appId: "1:460641879600:web:9822df8c3da6f7d15b0ff5"
};

export const app = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, process.env.REACT_APP_FIREBASE_REGION);

export const firebaseObserver = ReactObserver();
firebase.auth().onAuthStateChanged(function(user) {
  firebaseObserver.publish("authStateChanged", user);
  if (user) {
    user.getIdTokenResult().then(function(data) {
      firebaseObserver.publish("authAdminClaim", data.claims?.admin);
      firebaseObserver.publish("authSuperAdminClaim", data.claims?.superadmin);
      firebaseObserver.publish("authOperatorClaim", data.claims?.operator);
    });
  } else {
    firebaseObserver.publish("authAdminClaim", false);
    firebaseObserver.publish("authSuperAdminClaim", false);
    firebaseObserver.publish("authOperatorClaim", false);
  }
});

// use ReactObserver instead
// export async function isAuth() {
//   await firebase.auth().onAuthStateChanged(function(user) {
//     if (user) {
//       return true;
//     } else {
//       return false;
//     }
//   });
// }

// export async function isAdmin() {
//   await firebase.auth().onAuthStateChanged(function(user) {
//     if (user) {
//       user.getIdTokenResult().then(function(data) {
//         return data.claims?.admin;
//       });
//     } else {
//       return false;
//     }
//   });
// }

// export async function isSuperAdmin() {
//   await firebase.auth().onAuthStateChanged(function(user) {
//     if (user) {
//       user.getIdTokenResult().then(function(data) {
//         return data.claims?.superadmin;
//       });
//     } else {
//       return false;
//     }
//   });
// }
