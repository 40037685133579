import * as React from "react";
import { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./PoDetails.module.css";
import { DateTime } from "luxon";
import { firebaseObserver, storage } from '../../firebaseConfig';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import ImagePopUpModal from "../../components/ui-common/ImagePopUpModal";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import PoItemAddModal from "../../components/functional/PoItemAddModal";
import { updatePurchaseOrderDocument, updatePurchaseOrderItemsDocument } from "../../components/database/purchase-order/updatePurchaseOrder";
import { insertPurchaseOrderItemsDocument } from "../../components/database/purchase-order/insertPurchaseOrder";
import { getPurchasePrderDocument, getPurchasePrderItemsDocument } from "../../components/database/purchase-order/getPurchaseOrder";
import { searchSupplierDocument } from "../../components/database/supplier/searchSupplier";
import { deletePurchaseOrderItemsDocument } from "../../components/database/purchase-order/deletePurchaseOrder";
import { useAuthContext } from "../../components/context/useAuthContext";
import { PoStatusList } from "../../models/status";


const PoDetails = () => {

  const params = useParams();
  const purchaseOrderId = params.purchaseOrderId;

  const [status, setStatus] = useState('');
  const [supplierName, setSupplierName] = useState('');
  const [dateTransaction, setDateTransaction] = useState('');
  const [comments, setComments] = useState('');
  const [currency, setCurrency] = useState('');
  const [freightAmount, setFreightAmount] = useState('');
  // proformaInvoiceImgUrl input form listener
  const [proformaInvoiceImgUrl, setProformaInvoiceImgUrl] = useState('');
  const [proformaInvoiceFileName, setProformaInvoiceFileName] = useState('');
  const [proformaInvoiceHttpUrl, setProformaInvoiceHttpUrl] = useState('');
  // commercialInvoiceImgUrl input form listener
  const [commercialInvoiceImgUrl, setCommercialInvoiceImgUrl] = useState('');
  const [commercialInvoiceFileName, setCommercialInvoiceFileName] = useState('');
  const [commercialInvoiceHttpUrl, setCommercialInvoiceHttpUrl] = useState('');
  // Total Count of Items
  const [totalCount, setTotalCount] = useState(0);
  // modified by
  const [modifiedBy, setModifiedBy] = useState('');
  const [dateModified, setDateModified] = useState('');

  // User Auth Context
  const { user } = useAuthContext();
  // User Display Name
  const [displayName, setDisplayName] = useState(user?.displayName);
  // PO items
  const [items, setItems] = useState([]);
  // PO orig items
  const [origItems, setOrigItems] = useState([]);
  // Supplier drop down list
  const [supplierList, setSupplierList] = useState([]);
  // Edit Switch
  const [enableEdit, setEnableEdit] = useState(false);
  // Create user Result
  const [submitSuccess, setSubmitSuccess] = useState(false);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // form validation state
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
    });
    getSupplierList();
    searchPo();
    searchPoItems();
    return () => { firebaseObserver.unsubscribe('authStateChanged'); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[displayName]);


  // DB call functions
  async function searchPo() {
    console.log('USE EFFECT EXECUTED');
    const docsSnapshot = await getPurchasePrderDocument(purchaseOrderId);
    populateFields(docsSnapshot.docs[0].data());
  }

  async function searchPoItems() {
    const items = await getPurchasePrderItemsDocument(purchaseOrderId);
    setItems(items);
    setOrigItems(items);
  }

  async function getSupplierList() {
    const supplierResult = await searchSupplierDocument("supplierName",'','');
    setSupplierList(supplierResult.docs);
  }
  // end db call functions

  // Input Listeners
  function statusListener(status) {
    setStatus(status);
  }
  function supplierNameListener(supplier) {
    setSupplierName(supplier);
  }
  function dateTransactionListener(event) {
    setDateTransaction(event.target.value);
  }
  function commentsListener(event) {
    setComments(event.target.value);
  }
  function currencyListener(event) {
    setCurrency(event.target.value);
  }
  function freightAmountListener(event) {
    // let formattedNumber;
    // if (event.target.value.length > 3) {
    //   const cleanInput = event.target.value.replace(',','');
    //   formattedNumber = Number(cleanInput).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    // } else {
    //   formattedNumber = event.target.value;
    // }
    // setFreightAmount(formattedNumber);
    setFreightAmount(event.target.value);
  }
  function proformaInvoiceImgUrlListener(event) {
    setProformaInvoiceFileName(event.target.value);
    setProformaInvoiceImgUrl(event.target.files[0]);
  }
  function commercialInvoiceImgUrlListener(event) {
    setCommercialInvoiceFileName(event.target.value);
    setCommercialInvoiceImgUrl(event.target.files[0]);
  }
  function itemIdListener(itemId, index) {
    const tempItemsArray = [].concat(items);
    const tempItem = tempItemsArray[index];
    const newItem = {...tempItem, itemId: itemId};
    tempItemsArray[index] = newItem;
    setItems(tempItemsArray);
  }
  function quantityListener(quantity, index) {
    const tempItemsArray = [].concat(items);
    const tempItem = tempItemsArray[index];
    const newItem = {...tempItem, quantity: quantity, updated: true};
    tempItemsArray[index] = newItem;
    setItems(tempItemsArray);

    let totalItemCount = 0;
    for (const item of tempItemsArray) {
      totalItemCount += parseInt(item.quantity);
    }
    setTotalCount(totalItemCount);
  }
  function unitCostListener(unitCost, index) {
    const tempItemsArray = [].concat(items);
    const tempItem = tempItemsArray[index];
    const newItem = {...tempItem, unitCost: unitCost};
    tempItemsArray[index] = newItem;
    setItems(tempItemsArray);
  }

  async function editPurchaseOrder(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }
    const purchaseOrderData = {
      purchaseOrderId: purchaseOrderId,
      status: status,
      supplierName: supplierName,
      currency: currency,
      freightAmount: freightAmount,
      dateTransaction: dateTransaction,
      totalCount: totalCount,
      comments: comments,
    };
    const purchaseOrderItemsData = [];
    for (const i of items) {
      const item = {
        purchaseOrderId: purchaseOrderId,
        supplierName: supplierName,
        brand: i.brand,
        division: i.division,
        itemId: i.itemId,
        itemDescription: i.itemDescription,
        itemType: i.itemType,
        itemSubType: i.itemSubType,
        quantity: i.quantity,
        purchasePrice: i.purchasePrice,
        sellingPrice1: i.sellingPrice1,
        sellingPrice2: i.sellingPrice2,
        sellingPrice3: i.sellingPrice3,
        sellingPrice4: i.sellingPrice4,
        sellingPrice5: i.sellingPrice5,
      }
      purchaseOrderItemsData.push(item);
    }

    setLoaderState(true);
    if (proformaInvoiceImgUrl) {
      const imgRef = await uploadToFirebaseStorage(proformaInvoiceImgUrl);
      const imgHttpUrl = await getDownloadURL(imgRef);
      setProformaInvoiceHttpUrl(imgHttpUrl);
      purchaseOrderData.proformaInvoiceImgUrl = imgHttpUrl;
    }
    if (commercialInvoiceImgUrl) {
      const imgRef = await uploadToFirebaseStorage(commercialInvoiceImgUrl);
      const imgHttpUrl = await getDownloadURL(imgRef);
      setCommercialInvoiceHttpUrl(imgHttpUrl);
      purchaseOrderData.commercialInvoiceImgUrl = imgHttpUrl;
    }
    // write to PO collection
    await updatePurchaseOrderDocument(purchaseOrderId, purchaseOrderData, displayName);
    // Check for any PO Items that need to edited
    for (const item of items) {
      if (item.updated && item.docId) {
        const itemDocId = item.docId;
        delete item.updated;
        await updatePurchaseOrderItemsDocument(itemDocId, item, displayName);
      } else if (item.updated && !item.docId) {
        delete item.updated;
        item.purchaseOrderId = purchaseOrderId;
        await insertPurchaseOrderItemsDocument(item, displayName);
      }
    }
    // Check for any PO Items that need to deleted
    for (const origItem of origItems) {
      const itemFound = items.find(item => item.docId === origItem.docId);
      if (!itemFound) {
        // await deleteDoc(doc(purchaseOrderItemsRef, origItem.docId));
        await deletePurchaseOrderItemsDocument(origItem.docId);
      }
    }

    setLoaderState(false);
    setSubmitSuccess(true);
    setValidated(false);
  }

  // upload Images
  async function uploadToFirebaseStorage(file) {
    const storageRef = ref(storage, `/purchase-order/${purchaseOrderId}/${file.name}`);
    await uploadBytes(storageRef, file).then(async (snapshot) => {});
    return storageRef;
  }

  // // delete Images
  // async function deleteFirebaseStorageFile(file) {
  //   const storageRef = ref(storage, `/purchase-order/${purchaseOrderId}/${file.name}`);
  //   await deleteObject(storageRef, file).then(async (snapshot) => {});
  //   return storageRef;
  // }

  function removeItemHandler(index) {
    let tempItemsArray = [].concat(items);
    // Do not delete first item. Just blank out the date
    if (index === 0 && tempItemsArray.length === 0) {
      tempItemsArray = [''];
    } else {
      tempItemsArray.splice(index,1);
    }
    setItems(tempItemsArray);
  }

  function toggleEdit() {
    if (enableEdit) {
      alert('You will lose the changes you made. Please click the "Modify Purchase Order and Save" Button next time if you want to save your changes');
      searchPo();
      searchPoItems();
    }
    setEnableEdit(!enableEdit);
    setValidated(false);
  }

  function populateFields(poDocument) {
    setStatus(poDocument.status);
    setSupplierName(poDocument.supplierName);
    setDateTransaction(poDocument.dateTransaction);
    setComments(poDocument.comments);
    setCurrency(poDocument.currency);
    setFreightAmount(poDocument.freightAmount);
    setProformaInvoiceImgUrl('');
    setProformaInvoiceFileName('');
    setProformaInvoiceHttpUrl(poDocument.proformaInvoiceImgUrl);
    setCommercialInvoiceImgUrl('');
    setCommercialInvoiceFileName('');
    setCommercialInvoiceHttpUrl(poDocument.commercialInvoiceImgUrl);
    setTotalCount(poDocument.totalCount);
    setModifiedBy(poDocument.modifiedBy);
    setDateModified(poDocument.dateModified.toDate());
    setSubmitSuccess(false);
    setDateTransaction(DateTime.local().toFormat('yyyy-MM-dd').toString());
  }

  function addItemConfirmation(selectedItem) {
    const tempItemsArray = [].concat(items);
    if (tempItemsArray.length > 1
        && tempItemsArray[tempItemsArray.length -1] === '') {
      alert("Please don't leave a blank item before adding a new one.")
      return;
    }
    tempItemsArray.push(
      {
        itemId: selectedItem.itemId,
        itemDescription: selectedItem.itemDescription,
        quantity: 0,
        brand: selectedItem.brand,
        division: selectedItem.division,
        itemType: selectedItem.itemType,
        itemSubType: selectedItem.itemSubType,
        purchasePrice: selectedItem.purchasePrice,
        sellingPrice1: selectedItem.sellingPrice1,
        sellingPrice2: selectedItem.sellingPrice2,
        sellingPrice3: selectedItem.sellingPrice3,
        sellingPrice4: selectedItem.sellingPrice4,
        sellingPrice5: selectedItem.sellingPrice5,
      }
    );
    setItems(tempItemsArray);
  }

  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center style={{margin:15}}>
        <h3 style={{display:"inline"}}>Purchase Order </h3>
        { enableEdit
          ? <i className='fas fa-lock-open' onClick={toggleEdit}></i>
          : <i className='fas fa-lock' onClick={toggleEdit}></i>
        }
      </center>
        { submitSuccess
          ? <center>
              <div className={classes.mainInputContainer}>
                <h3>Purchase Order Successfully Edited.</h3>
                Purchase Order ID: {purchaseOrderId}
                { proformaInvoiceHttpUrl
                  ? <ImagePopUpModal
                    key={proformaInvoiceHttpUrl}
                    imgUrlIcon={proformaInvoiceHttpUrl}
                    imgUrl={proformaInvoiceHttpUrl}
                    />
                  : null
                }
                &nbsp;
                { commercialInvoiceHttpUrl
                  ? <ImagePopUpModal
                    key={commercialInvoiceHttpUrl}
                    imgUrlIcon={commercialInvoiceHttpUrl}
                    imgUrl={commercialInvoiceHttpUrl}
                    />
                  : null
                }
                <br></br><br></br>
                <Button variant="primary" onClick={() => {window.location.reload(false)}}>View PO Details</Button>
                &nbsp;
                <Button variant="primary" onClick={() => {navigate('/pocreate')}}>Create Another PO</Button>
                &nbsp;
                <Button variant="primary" onClick={() => {navigate('/')}}>Exit</Button>
              </div>
            </center>
          : <div className={classes.mainInputContainer}>
            { enableEdit
              ? <Form noValidate validated={validated} onSubmit={editPurchaseOrder}>
                <Form.Group className="mb-3" controlId="inputPurchaseOrder">
                  <Form.Label column="lg">Purchase Order ID</Form.Label>
                  <Form.Control type="text" disabled={true} value={purchaseOrderId} />
                  <Form.Label column="lg">Purchase Order Status</Form.Label>
                  <InputGroup className="mb-1" size="sm">
                  <DropdownButton id="status-dropdown-button" title={status}>
                  {
                    PoStatusList.map((status) => (
                      <Dropdown.Item
                        key={status}
                        onClick={() => {statusListener(status)}}
                      >
                        {status}
                      </Dropdown.Item>
                    ))
                  }
                  </DropdownButton>
                  </InputGroup>
                  <Form.Label column="lg">Details</Form.Label>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="supplier-name">Supplier Name</InputGroup.Text>
                  <DropdownButton id="supplier-dropdown-button" title={supplierName}>
                  {
                    supplierList.map((val) => (
                      <Dropdown.Item
                        key={val.supplierName}
                        onClick={() => {supplierNameListener(val.supplierName)}}
                      >
                        {val.supplierName}
                      </Dropdown.Item>
                    ))
                  }
                  </DropdownButton>
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="transaction-date">Transaction Date</InputGroup.Text>
                  <Form.Control
                    type="date"
                    placeholder="Transaction Date"
                    value={dateTransaction}
                    inputMode='none'
                    onChange={dateTransactionListener}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="freight-amount">Freight Amount</InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    pattern="^[1-9][0-9]*$"
                    value={freightAmount}
                    onChange={freightAmountListener}
                  />
                  <Form.Control.Feedback type="invalid">
                    Freight amount should be a number and not be 0 or blank.
                  </Form.Control.Feedback>
                  <InputGroup.Text id="currency">Currency</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="php"
                    value={currency}
                    onChange={currencyListener}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="comments">Comments</InputGroup.Text>
                  <Form.Control
                    as="textarea"
                    aria-label="With textarea"
                    placeholder="comments"
                    value={comments}
                    onChange={commentsListener}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="po-image">Proforma Invoice</InputGroup.Text>
                  <Form.Control
                    type="file"
                    value={proformaInvoiceFileName}
                    onChange={proformaInvoiceImgUrlListener}
                  />
                  </InputGroup>
                  { proformaInvoiceHttpUrl
                    ? <ImagePopUpModal
                      key={proformaInvoiceHttpUrl}
                      imgUrlIcon={proformaInvoiceHttpUrl}
                      imgUrl={proformaInvoiceHttpUrl}
                      />
                    : null
                  }
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="po-image">Commercial Invoice</InputGroup.Text>
                  <Form.Control
                    type="file" value={commercialInvoiceFileName}
                    onChange={commercialInvoiceImgUrlListener}
                  />
                  </InputGroup>
                  { proformaInvoiceHttpUrl
                    ? <ImagePopUpModal
                      key={commercialInvoiceHttpUrl}
                      imgUrlIcon={commercialInvoiceHttpUrl}
                      imgUrl={commercialInvoiceHttpUrl}
                      />
                    : null
                  }
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="modifiedBy">Modified By</InputGroup.Text>
                  <Form.Control
                    type="text" value={modifiedBy}
                    disabled={true}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="po-image">Date Modified</InputGroup.Text>
                  <Form.Control
                    type="text" value={dateModified}
                    disabled={true}
                  />
                  </InputGroup>
                  {
                    items.map((val, index) => (
                      <div key={`items-${index}}`}
                        style={{border:"1px solid #1C6EA4",
                        borderRadius: "20px",
                        margin: "5px",
                        padding: "5px"}}
                        className={classes.inputBoxRadio}
                      >
                        Item {index+1}:
                      &nbsp;
                      <Button
                        variant="danger"
                        onClick={() =>{removeItemHandler(index)}}
                        size="sm"
                        style={{marginBottom:5}}
                      >
                        delete Item
                      </Button>
                      <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id={`itemId-${index}`}>Item Id</InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Item Id"
                        value={items[index].itemId}
                        onChange={(event) => {itemIdListener(event.target.value, index)}}
                        required={true}
                        disabled={true}
                      />
                      </InputGroup>
                      <InputGroup className="mb-1" size="sm">
                      <InputGroup.Text id={`quantity-${index}`}>Quantity</InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        pattern="^[1-9][0-9]*$"
                        placeholder="0"
                        value={items[index].quantity}
                        onChange={(event) => {quantityListener(event.target.value, index)}}
                      />
                      <Form.Control.Feedback type="invalid">
                        Quantity should be a number and not be 0 or blank.
                      </Form.Control.Feedback>
                      <InputGroup.Text id={`unitCost-${index}`}>Unit Cost</InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="0"
                        value={items[index].unitCost}
                        onChange={(event) => {unitCostListener(event.target.value, index)}}
                      />
                      </InputGroup>
                      </div>
                    ))
                  }
                  <PoItemAddModal
                    supplierName={supplierName}
                    onConfirmation={addItemConfirmation}
                  />
                  <br></br>
                  <center>
                  <Button
                    type="submit"
                  >
                    Modify Purchase Order and Save
                  </Button>
                  </center>
                </Form.Group>
                </Form>
              : <Form>
                <Form.Group className="mb-3" controlId="inputPurchaseOrderNoEdit">
                  <Form.Label column="lg">Purchase Order ID</Form.Label>
                  <Form.Control type="text" disabled={true} value={purchaseOrderId} />
                  <Form.Label column="lg">Purchase Order Status</Form.Label>
                  <InputGroup className="mb-1" size="sm">
                  <Form.Control
                    type="text"
                    value={status}
                    disabled={true}
                  />
                  </InputGroup>
                  <Form.Label column="lg">Details</Form.Label>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="supplier-name">Supplier Name</InputGroup.Text>
                  <Form.Control
                    type="text" placeholder="Supplier Name" value={supplierName}
                    onChange={supplierNameListener}
                    required={true}
                    disabled={true}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="transaction-date">Transaction Date</InputGroup.Text>
                  <Form.Control
                    type="date" placeholder="Transaction Date" value={dateTransaction} inputMode='none'
                    onChange={dateTransactionListener}
                    disabled={true}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="freight-amount">Freight Amount</InputGroup.Text>
                  <Form.Control
                    type="currency" placeholder="amount" value={freightAmount}
                    onChange={freightAmountListener}
                    disabled={true}
                  />
                  <InputGroup.Text id="currency">Currency</InputGroup.Text>
                  <Form.Control
                    type="text" placeholder="php" value={currency}
                    onChange={currencyListener}
                    disabled={true}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="comments">Comments</InputGroup.Text>
                  <Form.Control
                    as="textarea" aria-label="With textarea" placeholder="comments" value={comments}
                    onChange={commentsListener}
                    disabled={true}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="po-image">Proforma Invoice</InputGroup.Text>
                  <Form.Control
                    type="file" value={proformaInvoiceFileName}
                    onChange={proformaInvoiceImgUrlListener}
                    disabled={true}
                  />
                  </InputGroup>
                  { proformaInvoiceHttpUrl
                    ? <ImagePopUpModal
                      key={proformaInvoiceHttpUrl}
                      imgUrlIcon={proformaInvoiceHttpUrl}
                      imgUrl={proformaInvoiceHttpUrl}
                      />
                    : null
                  }
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="po-image">Commercial Invoice</InputGroup.Text>
                  <Form.Control
                    type="file" value={commercialInvoiceFileName}
                    onChange={commercialInvoiceImgUrlListener}
                    disabled={true}
                  />
                  </InputGroup>
                  { proformaInvoiceHttpUrl
                    ? <ImagePopUpModal
                      key={commercialInvoiceHttpUrl}
                      imgUrlIcon={commercialInvoiceHttpUrl}
                      imgUrl={commercialInvoiceHttpUrl}
                      />
                    : null
                  }
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="modifiedBy">Modified By</InputGroup.Text>
                  <Form.Control
                    type="text" value={modifiedBy}
                    disabled={true}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="po-image">Date Modified</InputGroup.Text>
                  <Form.Control
                    type="text" value={dateModified}
                    disabled={true}
                  />
                  </InputGroup>
                  {
                    items.map((val, index) => (
                      <div key={`items-${index}}`}
                        style={{border:"1px solid #1C6EA4",
                                borderRadius: "20px",
                                margin: "5px",
                                padding: "5px"}}
                        className={classes.inputBoxRadio}>
                        Item {index+1}:
                        &nbsp;
                        <InputGroup className="mb-1" size="sm">
                          <InputGroup.Text id={`itemId-${index}`}>Item Id</InputGroup.Text>
                          <Form.Control
                            type="text" placeholder="Item Name" value={items[index].itemId}
                            onChange={(event) => {itemIdListener(event.target.value, index)}}
                            required={true}
                            disabled={true}
                          />
                          </InputGroup>
                          <InputGroup className="mb-1" size="sm">
                          <InputGroup.Text id={`quantity-${index}`}>Quanitty</InputGroup.Text>
                          <Form.Control
                            type="text" placeholder="0" value={items[index].quantity}
                            onChange={(event) => {quantityListener(event.target.value, index)}}
                            disabled={true}
                          />
                          <InputGroup.Text id={`unitCost-${index}`}>Unit Cost</InputGroup.Text>
                          <Form.Control
                            type="text" placeholder="0" value={items[index].unitCost}
                            onChange={(event) => {unitCostListener(event.target.value, index)}}
                            disabled={true}
                          />
                        </InputGroup>
                      </div>
                    ))
                  }
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="po-image">Total Item Count</InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={totalCount}
                    disabled={true}
                  />
                  </InputGroup>
                  <center><Button variant="primary" onClick={() => {navigate('/');}}>Exit</Button></center>
                </Form.Group>
                </Form>
            }
          </div>
        }
        </div>
      </CustomLoader>
  );
}

export default PoDetails;
