import React from "react";
import ReactDOM from "react-dom";
import { AuthProvider } from "./components/context/AuthProvider.js";
import App from "./App.js";
import "./index.css";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import ManageUsers from "./pages/Admin/ManageUsers";
import CreateUser from "./pages/Admin/CreateUser.js";
import SignOut from "./pages/SignOut.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import UserProfile from "./pages/User/UserProfile.js";
import ManageBroadcasts from "./pages/Admin/ManageBroadcasts.js";
import ManageAdmins from "./pages/SuperAdmin/ManageAdmins.js";
import UserHelp from "./pages/User/UserHelp.js";
import AdminHelp from "./pages/Admin/AdminHelp.js";
import SuperAdminHelp from "./pages/SuperAdmin/SuperAdminHelp.js";
import FirstAdmin from "./pages/SuperAdmin/FirstSetupAdmin.js";
import PoSearch from "./pages/PurchaseOrder/PoSearch.js";
import PoDetails from "./pages/PurchaseOrder/PoDetails.js";
import PoCreate from "./pages/PurchaseOrder/PoInput.js";
import SupplierInput from "./pages/Supplier/SupplierInput.js";
import SupplierSearch from "./pages/Supplier/SupplierSearch.js";
import SupplierDetails from "./pages/Supplier/SupplierDetails.js";
import ItemMasterInput from "./pages/ItemsMaster/ItemMasterInput.js";
import ItemMasterSearch from "./pages/ItemsMaster/ItemMasterSearch.js";
import ItemMasterDetails from "./pages/ItemsMaster/ItemMasterDetails.js";
import BrandInputSearch from "./pages/ItemsMaster/BrandInputSearch.js";
import ItemTypeInputSearch from "./pages/ItemsMaster/ItemTypeInputSearch.js";
import ItemSubTypeInputSearch from "./pages/ItemsMaster/ItemSubTypeInputSearch.js";
import ReceivingMassInput from "./pages/MassLoader/ReceivingMassInput.js";
import DivisionInputSearch from "./pages/ItemsMaster/DivisionInputSearch.js";
import ItemMasterMassInput from "./pages/MassLoader/ItemMasterMassInput.js";
import JobSearch from "./pages/MassLoader/JobSearch.js";
import JobDetails from "./pages/MassLoader/JobDetails.js";

ReactDOM.render(
    <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/home" element={<Home />} />
        <Route path="/userprofile" element={<UserProfile />} />
        <Route path="/userhelp" element={<UserHelp />} />
        <Route path="/manageadmins" element={<ManageAdmins />} />
        <Route path="/manageusers" element={<ManageUsers />} />
        <Route path="/adminhelp" element={<AdminHelp />} />
        <Route path="/superadminhelp" element={<SuperAdminHelp />} />
        <Route path="/createuser" element={<CreateUser />} />
        <Route path="/managebroadcasts" element={<ManageBroadcasts />} />
        <Route path="/signout" element={<SignOut />} />
        <Route path="/backdoor" element={<FirstAdmin />} />
        <Route path="/pocreate" element={<PoCreate allowedRolesList={['ADMIN','SUPERADMIN','WAREHOUSE']}/>} />
        <Route path="/posearch" element={<PoSearch />} />
        <Route path="/podetails/:purchaseOrderId" element={<PoDetails />} />
        <Route path="/suppliercreate" element={<SupplierInput />} />
        <Route path="/suppliersearch" element={<SupplierSearch />} />
        <Route path="/supplierdetails/:supplierName" element={<SupplierDetails />} />
        <Route path="/itemmastercreate" element={<ItemMasterInput />} />
        <Route path="/itemmastersearch" element={<ItemMasterSearch />} />
        <Route path="/itemmasterdetails/:itemId" element={<ItemMasterDetails />} />
        <Route path="/brandinputsearch" element={<BrandInputSearch />} />
        <Route path="/divisioninputsearch" element={<DivisionInputSearch />} />
        <Route path="/itemtypeinputsearch" element={<ItemTypeInputSearch />} />
        <Route path="/itemsubtypeinputsearch" element={<ItemSubTypeInputSearch />} />
        <Route path="/receivingmassloading" element={<ReceivingMassInput />} />
        <Route path="/itemmastermassloading" element={<ItemMasterMassInput />} />
        <Route path="/jobs" element={<JobSearch />} />
        <Route path="/jobdetails/:jobId" element={<JobDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthProvider>
    </BrowserRouter>,
  document.getElementById("root")
);

// Only use once during initial deployment for first admin user.
// <Route path="/secret" element={<FirstAdmin />} />

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
