import { useState, useEffect } from "react";
import { firebaseObserver } from '../../firebaseConfig';
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import InputSearchPage from "../../components/functional/InputSearchPage";


const ItemTypeInputSearch = () => {

  // Dynamic Parameters
  const dbName = "item-type";
  const dbResultLimit = 100;
  const label = "Item Type";
  const elementObjectKey = "itemType";
  const elementObjectDescKey = `${elementObjectKey}Description`;

  // User Auth Context
  const { user } = useAuthContext();
  // User Display Name
  const [displayName, setDisplayName] = useState(user?.displayName);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[displayName]);

  return (
    <InputSearchPage
      dbName={dbName}
      dbResultLimit={dbResultLimit}
      label={label}
      elementObjectKey={elementObjectKey}
      elementObjectDescKey={elementObjectDescKey}
      displayName={displayName}
    />
  );
}

export default ItemTypeInputSearch;
