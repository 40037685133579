
function RolesChecker(userRolesList, allowedRolesList) {

  let checkStatus = false;
  userRolesList.forEach(userRole => {
    allowedRolesList.forEach(allowedRole => {
      if (userRole === allowedRole) {
        checkStatus = true;
      }
    })
  });
  return checkStatus;
}


export default RolesChecker

