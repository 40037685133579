import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import { updateDocument } from "../database/standard/update";


const DetailsDataListModal = (props) => {

  const docId = props.docId;
  const label = props.label;
  const dbName = props.dbName;
  const elementObjectKey = props.elementObjectKey;
  const elementObjectDescKey = props.elementObjectDescKey;
  const [element, setElement] = useState(props.element);
  const [elementDescription, setElementDescription] = useState(props.elementDescription);

  const [show, setShow] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const displayName = props.displayName;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function elementListener(event) {
    setElement(event.target.value.toUpperCase());
  }
  function elementDescriptionListener(event) {
    setElementDescription(event.target.value);
  }

  async function editElement() {
    const elementData = {
      [elementObjectKey]: element,
      [elementObjectDescKey]: elementDescription
    };
    const insertSuccess = await updateDocument(dbName, docId, elementData, displayName);
    if (!insertSuccess){
      return;
    }
    setElement(element);
    setShow(false);
  }

  function toggleEdit() {
    if (enableEdit) {
      alert('You will lose the changes you made. Please click the "Save and Exit" Button next time if you want to save your changes');
    }
    setEnableEdit(!enableEdit);
  }

  return (
    <React.Fragment>
      <ListGroup.Item key={element} action onClick={handleShow}>
        <div className="ms-2 me-auto">
          <b>{label}</b>: {element}
          &nbsp; <b>{label} Description:</b> {elementDescription}
        </div>
      </ListGroup.Item>
      <Modal
        size="m"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header closeButton>
          <Modal.Title>Edit {label}&nbsp;
            { enableEdit
              ? <i className='fas fa-lock-open' onClick={toggleEdit}></i>
              : <i className='fas fa-lock' onClick={toggleEdit}></i>
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { enableEdit
            ? <Form>
              <Form.Group className="mb-3" controlId="inputItem">
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="element">{label}</InputGroup.Text>
                <Form.Control
                  type="text" placeholder={label} value={element}
                  onChange={elementListener}
                  disabled={true}
                />
                </InputGroup>
                <InputGroup className="mb-1" size="sm">
                <InputGroup.Text id="itemTypeDescription">{label} Description</InputGroup.Text>
                <Form.Control
                  type="text" placeholder={label.concat(" Description")} value={elementDescription}
                  onChange={elementDescriptionListener}
                />
                </InputGroup>
              </Form.Group>
              </Form>
            : <Form>
            <Form.Group className="mb-3" controlId="inputItem">
              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="element">{label}</InputGroup.Text>
              <Form.Control
                type="text" placeholder={label} value={element}
                onChange={elementListener}
                disabled={true}
              />
              </InputGroup>
              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="itemTypeDescription">{label} Description</InputGroup.Text>
              <Form.Control
                type="text" placeholder={label.concat(" Description")} value={elementDescription}
                onChange={elementDescriptionListener}
                disabled={true}
              />
              </InputGroup>
            </Form.Group>
            </Form>
          }
          <center><Button variant="primary" onClick={editElement}>Save and Exit</Button></center>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default DetailsDataListModal

