import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ListGroup from 'react-bootstrap/ListGroup';
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./ReceivingMassInput.module.css";
import { firebaseObserver, storage } from '../../firebaseConfig';
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import { searchDocuments } from "../../components/database/standard/search";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { listDocuments } from "../../components/database/standard/get";
import { searchNextDocuments } from "../../components/database/standard/searchNext";
import { searchPrevDocuments } from "../../components/database/standard/searchPrev";
import FullTextSearch from "../../utilities/FullTextSearch";
import short from "short-uuid";
import { DateTime } from "luxon";
import { JobStatus, JobType } from "../../models/jobs";
import { Timestamp } from "firebase/firestore/lite";
import { ref, uploadBytes } from "firebase/storage";
import { insertDocument } from "../../components/database/standard/insert";
import { updateDocument } from "../../components/database/standard/update";
import { Fragment } from "react";
import DownloadFileFromStorage from "../../components/functional/DownloadFileFromStorage";
import PoSearchComponent from "../../components/functional/PoSearchComponent";
import { ItemStatusEnum } from "../../models/status";


const ReceivingMassInput = () => {

  const dbResultLimit = 100;
  // Items List
  const [origItems, setOrigItems] = useState([]);
  const [items, setItems] = useState([]);
  // First Document in query pagination
  const [firstVisible, setFirstVisible] = useState();
  // Last Document in query pagination
  const [lastVisible, setLastVisible] = useState();
  // Previous button switch
  const [disablePrevButton, setDisablePrevButton] = useState(true);
  // Next button switch
  const [disableNextButton, setDisableNextButton] = useState(true);
  // Search Value
  const [searchInput, setSearchInput] = useState('');

  // Brand List
  const [brandList, setBrandList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  // Division List
  const [divisionList, setDivisionList] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState('');
  // Item Type List
  const [itemTypeList, setItemTypeList] = useState([]);
  const [selectedItemType, setSelectedItemType] = useState('');
  // Item SubType List
  const [itemSubTypeList, setItemSubTypeList] = useState([]);
  const [selectedItemSubType, setSelectedItemSubType] = useState('');

  // Po loading
  const [selectedPo, setSelectedPo] = useState('');

  // Item Loading
  const [selectedItem, setSelectedItem] = useState('');
  const [fileUpload, setFileUpload] = useState('');
  const [fileUploadFileName, setFileUploadFileName] = useState('');
  const [fileName, setFileName] = useState('');
  const filePath = '/data-loading/receiving-register/';
  const jobId = generateJobId(DateTime.local());

  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [loaderState, setLoaderState] = useState(false);
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
    });
    getBrandList();
    getDivisionList();
    getItemTypeList();
    getItemSubTypeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[displayName]);

  // On load functions
  function generateJobId(dateToday) {
    const formattedDate = `${dateToday.day}${dateToday.monthShort.toLocaleUpperCase()}${dateToday.year}`;
    return `JOB-${formattedDate}-${short.generate()}`;
  }
  async function getBrandList() {
    const queryResult = await listDocuments("brand", 'brand', 'brand', 'asc');
    setBrandList([...[{brand:"BLANK"}],...queryResult]);
  }
  async function getDivisionList() {
    const queryResult = await listDocuments("division", 'division', 'division', 'asc');
    setDivisionList([...[{division:"BLANK"}],...queryResult]);
  }
  async function getItemTypeList() {
    const queryResult = await listDocuments("item-type", 'itemType', 'itemType', 'asc');
    setItemTypeList([...[{itemType:"BLANK"}],...queryResult]);
  }
  async function getItemSubTypeList() {
    const queryResult = await listDocuments("item-sub-type", 'itemSubType', 'itemSubType', 'asc');
    setItemSubTypeList([...[{itemSubType:"BLANK"}],...queryResult]);
  }
  // End of on load functions

  // Functions for searching items
  function selectedBrandListener(brand) {
    if (brand === "BLANK") {
      setSelectedBrand("");
    } else {
      setSelectedBrand(brand);
    }
  }
  function selectedDivisionListener(division) {
    if (division === "BLANK") {
      setSelectedDivision("");
    } else {
      setSelectedDivision(division);
    }
  }
  function selectedItemTypeListener(itemType) {
    if (itemType === "BLANK") {
      setSelectedItemType("");
    } else {
      setSelectedItemType(itemType);
    }
  }
  function selectedItemSubTypeListener(itemSubType) {
    if (itemSubType === "BLANK") {
      setSelectedItemSubType("");
    } else {
      setSelectedItemSubType(itemSubType);
    }
  }
  function searchInputListener(event) {
    setSearchInput(event.target.value);
    setItems(FullTextSearch(origItems, event.target.value, "itemId", true));
  }

  function parseQueryFilter() {
    const queryFilters = [];
    if (selectedBrand) {
      queryFilters.push({key:"brand",inputValue:selectedBrand});
    }
    if (selectedDivision) {
      queryFilters.push({key:"division",inputValue:selectedDivision});
    }
    if (selectedItemType) {
      queryFilters.push({key:"itemType",inputValue:selectedItemType});
    }
    if (selectedItemSubType) {
      queryFilters.push({key:"itemSubType",inputValue:selectedItemSubType});
    }
    return queryFilters;
  }

  function selectItemForLoading(arrayIndex) {
    setSelectedItem(items[arrayIndex]);
  }

  async function generateList() {
    setLoaderState(true);
    const queryFilters = parseQueryFilter();
    let itemResult;
    try {
      itemResult = await searchDocuments("item-master", queryFilters, dbResultLimit, "itemId", "asc");
      if (!itemResult) {
        setLoaderState(false);
        return;
      }
    } catch(error) {
      console.log(`Error in DB query: ${error}`);
      setLoaderState(false);
      return;
    }
    setOrigItems(itemResult.docs);
    setItems(itemResult.docs);
    setFirstVisible(itemResult.firstVisibleDocument);
    setLastVisible(itemResult.lastVisibleDocument);
    if (itemResult.isNextAvailable) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
    setDisablePrevButton(true);
    setLoaderState(false);
  }

  async function getNext() {
    setLoaderState(true);
    const queryFilters = parseQueryFilter();
    const itemResult = await searchNextDocuments("item-master", queryFilters, dbResultLimit, lastVisible, "itemId", "asc");
    setItems(itemResult.docs);
    setFirstVisible(itemResult.firstVisibleDocument);
    setLastVisible(itemResult.lastVisibleDocument);
    if (itemResult.isNextAvailable) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
    setDisablePrevButton(false);
    setLoaderState(false);
  }

  async function getPrev() {
    setLoaderState(true);
    const queryFilters = parseQueryFilter();
    const itemResult = await searchPrevDocuments("item-master", queryFilters, dbResultLimit, firstVisible, "itemId", "asc");
    setItems(itemResult.docs);
    setFirstVisible(itemResult.firstVisibleDocument);
    setLastVisible(itemResult.lastVisibleDocument);
    if (itemResult.isPrevAvailable) {
      setDisablePrevButton(false);
    } else {
      setDisablePrevButton(true);
    }
    setDisableNextButton(false);
    setLoaderState(false);
  }
  // End of functions for searching items

  // Function for Loading file
  function fileUploadlListener(event) {
    const file = event.target.files[0];
    setFileUploadFileName(event.target.value);
    const fileNameTemp = file.name.split(".");
    const fileExtension = fileNameTemp[fileNameTemp.length - 1];
    setFileName(`${jobId}.${fileExtension}`);
    setFileUpload(event.target.files[0]);
  }

  async function uploadFile(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }
    let preparedData = prepareData();
    if (!preparedData) {
      return;
    }
    setLoaderState(true);
    const jobData = {
      jobId: fileName.split(".")[0],
      type: JobType.RECEIVING_REGISTER_LOADING,
      preparedData: preparedData,
      status: JobStatus.SUBMITTED,
      statusMessage: "Job Submitted",
      filePath: `${filePath}${fileName}`,
      startedAt: Timestamp.now(),
      triggeredBy: displayName,
    };
    try {
      if (fileUpload) {
        await insertDocument("jobs", "jobId", jobData, true, displayName);
      }
    } catch (error) {
      alert("Unable to upload the file");
      setLoaderState(false);
      return;
    }
    try {
      await uploadToFirebaseStorage(fileUpload);
    } catch (error) {
      jobData.status = JobStatus.ERROR;
      jobData.statusMessage = "Error uploading the file.";
      await updateDocument("jobs", jobId, jobData, displayName);
      setLoaderState(false);
    }
    setLoaderState(false);
    setSubmitSuccess(true);
  }

  // upload Images
  async function uploadToFirebaseStorage(file) {
    const storageRef = ref(storage, `${filePath}${fileName}`);
    await uploadBytes(storageRef, file).then(async (snapshot) => {});
    return storageRef;
  }

  function prepareData() {
    return {
      purchaseOrderId: selectedPo,
      brand: selectedItem.brand,
      division: selectedItem.division,
      itemType: selectedItem.itemType,
      itemSubType: selectedItem.itemSubType,
      itemId: selectedItem.itemId,
      itemDescription: selectedItem.itemDescription,
      serialMinLength: selectedItem.serialMinLength,
      serialMaxLength: selectedItem.serialMaxLength,
      upc: selectedItem.upc,
      purchasePrice: selectedItem.purchasePrice,
      sellingPrice1: selectedItem.sellingPrice1,
      sellingPrice2: selectedItem.sellingPrice2,
      sellingPrice3: selectedItem.sellingPrice3,
      sellingPrice4: selectedItem.sellingPrice4,
      sellingPrice5: selectedItem.sellingPrice5,
      itemStatus: ItemStatusEnum.IN_STOCK,
    };
  }
  // end of functions for loading file
  async function handleDownload() {
    await DownloadFileFromStorage("/data-loading/receiving-register-template.xlsx");
  }

  function onSelectHandler(poId) {
    setSelectedPo(poId);
  }


  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      { selectedPo
        ? <Fragment>
            { selectedItem
              ? <center><h3 style={{marginTop:15}}>Select File for Upload</h3></center>
              : <center><h3 style={{marginTop:15}}>Search Item to Load</h3></center>
            }
          </Fragment>
        : <center><h3 style={{marginTop:15}}>Select PO Related to Receiving Inventory</h3></center>
      }
      <div className={classes.mainInputContainer}>
      <center>
      { selectedPo
        ? <Fragment>
            { selectedItem
            ? <Fragment>
                { submitSuccess
                  ? <Fragment>
                      <h3>Items Have Been Submitted For Processing.</h3>
                      <Button variant="primary" onClick={() => {navigate(`/jobdetails/${fileName.split(".")[0]}`)}}>View Job Details</Button>
                      &nbsp;
                      <Button variant="primary" onClick={() => {window.location.reload(false)}}>Exit</Button>
                    </Fragment>
                  : <Form noValidate validated={validated} onSubmit={uploadFile}>
                    <Form.Group className="mb-3" controlId="inputItem">
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="brand"><b>Purchase Order Id</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedPo}
                    />
                    &nbsp;
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="brand"><b>Brand</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedItem.brand}
                    />
                    &nbsp;
                    <InputGroup.Text id="division"><b>Division</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedItem.division}
                    />
                    &nbsp;
                    <InputGroup.Text id="itemType"><b>Item Type</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedItem.itemType}
                    />
                    &nbsp;
                    <InputGroup.Text id="itemSubType"><b>Item Subtype</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedItem.itemSubType}
                    />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="itemId"><b>Item Id</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedItem.itemId}
                    />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="itemDescription"><b>Item Description</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedItem.itemDescription}
                    />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="serialMinLength"><b>Serial Min Length</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedItem.serialMinLength}
                    />
                    &nbsp;
                    <InputGroup.Text id="serialMaxLength"><b>Serial Min Length</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedItem.serialMaxLength}
                    />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="upc"><b>UPC Code</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedItem.upc}
                    />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="purchasePrice"><b>Purchase Price</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedItem.purchasePrice}
                    />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="sellingPrice1"><b>Selling Price 1</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedItem.sellingPrice1}
                    />
                    &nbsp;
                    <InputGroup.Text id="sellingPrice1"><b>Selling Price 2</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedItem.sellingPrice2}
                    />
                    &nbsp;
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="sellingPrice1"><b>Selling Price 3</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedItem.sellingPrice3}
                    />
                    &nbsp;
                    <InputGroup.Text id="sellingPrice1"><b>Selling Price 4</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedItem.sellingPrice4}
                    />
                    &nbsp;
                    <InputGroup.Text id="sellingPrice1"><b>Selling Price 5</b></InputGroup.Text>
                    <Form.Control
                      disabled
                      type="text"
                      value={selectedItem.sellingPrice5}
                    />
                    </InputGroup>
                    <InputGroup className="mb-1" size="sm">
                    <InputGroup.Text id="po-image"><b>File Upload</b></InputGroup.Text>
                    <Form.Control
                      type="file"
                      value={fileUploadFileName}
                      onChange={fileUploadlListener}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      Excel file is required.
                    </Form.Control.Feedback>
                    </InputGroup>
                    </Form.Group>
                    <center>
                      <Button variant="primary" onClick={handleDownload}>Download Template</Button>
                      &nbsp;
                      <Button type="submit">Upload File</Button>
                    </center>
                  </Form>
                }
              </Fragment>
            : <Fragment>
              <Form>
              <Form.Group className="mb-3" controlId="inputItem">
              <InputGroup className="mb-1" size="sm">
              <InputGroup.Text id="element">Brand</InputGroup.Text>
              <DropdownButton id="supplier-dropdown-button" title={selectedBrand}>
                {
                  brandList.map((val) => (
                    <Dropdown.Item key={val.brand} onClick={() => {selectedBrandListener(val.brand)}}>{val.brand}</Dropdown.Item>
                  ))
                }
              </DropdownButton>
              &nbsp; &nbsp;
              <InputGroup.Text id="element">Division </InputGroup.Text>
                <DropdownButton id="search-options-dropdown-button" title={selectedDivision}>
                  {
                    divisionList.map((val) => (
                      <Dropdown.Item key={val.division} onClick={() => {selectedDivisionListener(val.division)}}>{val.division}</Dropdown.Item>
                    ))
                  }
                </DropdownButton>
              &nbsp; &nbsp;
              <InputGroup.Text id="element">Item Type</InputGroup.Text>
                <DropdownButton id="search-options-dropdown-button" title={selectedItemType}>
                {
                  itemTypeList.map((val) => (
                    <Dropdown.Item key={val.itemType} onClick={() => {selectedItemTypeListener(val.itemType)}}>{val.itemType}</Dropdown.Item>
                  ))
                }
                </DropdownButton>
              &nbsp; &nbsp;
              <InputGroup.Text id="element">Item Sub Type</InputGroup.Text>
                <DropdownButton id="search-options-dropdown-button" title={selectedItemSubType}>
                {
                  itemSubTypeList.map((val) => (
                    <Dropdown.Item key={val.itemSubType} onClick={() => {selectedItemSubTypeListener(val.itemSubType)}}>{val.itemSubType}</Dropdown.Item>
                  ))
                }
                </DropdownButton>
              </InputGroup>
              <InputGroup className="mb-1" size="sm" style={{display:"inline-block", marginTop:5}}>
                <Button variant="primary" onClick={() => generateList()}>Generate List</Button>
              </InputGroup>
              <InputGroup className="mb-1" size="sm"style={{marginTop:5}}>
              <InputGroup.Text id="brand-name">Item Id</InputGroup.Text>
              <Form.Control
                type="searchItemId" placeholder="Type Search Text here" value={searchInput}
                onChange={searchInputListener}
              />
              </InputGroup>
              </Form.Group>
              </Form>
                <ListGroup style={{overflow:"scroll", maxHeight:"350px"}}>
                {
                  items.map((val, index) => (
                    <ListGroup.Item key={val.itemId} action onClick={() => {selectItemForLoading(index)}}>
                      <div className="ms-2 me-auto">
                        <b>Item Id</b>: {val.itemId}
                        &nbsp; <b>Item Description:</b> {val.itemDescription}
                        &nbsp; <b>Item Type:</b> {val.itemType}
                        &nbsp; <b>Brand:</b> {val.brand}
                        &nbsp; <b>Color:</b> {val.itemColor}
                      </div>
                    </ListGroup.Item>
                  ))
                }
                </ListGroup>
                <br></br>
                  { disablePrevButton
                    ? null
                    : <Button variant="primary" onClick={() => getPrev()}>Previous</Button>
                  }
                  &nbsp;&nbsp;
                  { disableNextButton
                    ? null
                    : <Button variant="primary" onClick={() => getNext()}>Next</Button>
                  }
              </Fragment>
            }
          </Fragment>
        : <PoSearchComponent isComponent={true} onSelect={onSelectHandler}/>
      }
      </center>
      </div>
    </div>
    </CustomLoader>
  );
}

export default ReceivingMassInput;
