import { db } from '../../../firebaseConfig';
import { collection, getDocs, doc, setDoc, query, where, Timestamp } from 'firebase/firestore/lite';

export const insertSupplierDocument = async (insertData, contextUser) => {
  const collectionRef = collection(db, "supplier");
  const q = query(collectionRef,
    where('supplierName','==', insertData.supplierName));
  const docsSnapshot = await getDocs(q);
  if (docsSnapshot.docs.length > 0) {
    alert('Supplier name already exists, please use a different supplier name or edit the existing one.')
    return false;
  }
  insertData.modifiedBy = contextUser;
  insertData.dateModified = Timestamp.now();
  await setDoc(doc(collectionRef), insertData);
  return true;
};
