export const UserRolesEnum = {
  WAREHOUSE: "WAREHOUSE",
  ENCODER: "ENCODER",
  SALES: "SALES",
  ACCOUNTING: "ACCOUNTING",
  LOGISTICS: "LOGISTICS",
  APPROVER_ADMIN: "APPROVER ADMIN",
  GENERIC: "GENERIC",
  BACKEND: "BACKEND",
  ADMIN: "ADMIN",
  SUPERADMIN: "SUPERADMIN"
}

export const UserRolesList = [
  "WAREHOUSE",
  "ENCODER",
  "SALES",
  "ACCOUNTING",
  "LOGISTICS",
  "APPROVER ADMIN",
  "GENERIC",
  "BACKEND",
  "ADMIN",
  "SUPERADMIN"
]
