import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ListGroup from 'react-bootstrap/ListGroup';
import CustomLoader from '../ui-common/CustomLoader';
// import classes from "./PoSearchComponent.module.css";
import { db, firebaseObserver } from '../../firebaseConfig';
import { collection, where, startAfter, limit, orderBy, query, getDocs, limitToLast, endBefore } from 'firebase/firestore/lite';
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/useAuthContext";
import { PoStatusList } from "../../models/status";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";


const PoSearchComponent = ({isComponent, onSelect}) => {

  // PO List
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [status, setStatus] = useState("OPEN");
  const [firstVisible, setFirstVisible] = useState();
  const [lastVisible, setLastVisible] = useState();
  const [queryFilterStart, setQueryFilterStart] = useState('');
  const [queryFilterEnd, setQueryFilterEnd] = useState('');
  const [disablePrevButton, setDisablePrevButton] = useState(true);
  const [disableNextButton, setDisableNextButton] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [searchOption, setSearchOption] = useState('Transaction Date (YYYY-MM-DD)');

  // User Auth Context
  const { user } = useAuthContext();
  // User Display Name
  const [displayName, setDisplayName] = useState(user?.displayName);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // Instantiate collection
  const purchaseOrderRef = collection(db, "purchase-order");

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
      console.log(displayName);
    });
    searchPo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function searchInputListener(event) {
    setSearchInput(event.target.value);
  }
  function statusListener(status) {
    setStatus(status);
  }
  function searchOptionListener(searchOption) {
    setSearchOption(searchOption);
    console.log(searchOption);
  }

  function enterKeySearch(event) {
    if (event.key === 'Enter') {
      searchPo(searchOption);
    }
  }

  function mapSearchOption(searchOption) {
    let mappedSearchOption;
    switch(searchOption) {
      case 'Transaction Date (YYYY-MM-DD)':
        mappedSearchOption = 'dateTransaction';
        break;
      case 'Purchase Order Id':
        mappedSearchOption = 'purchaseOrderId';
        break;
      case 'Supplier Name':
        mappedSearchOption = 'supplierName';
        break;
      default:
        mappedSearchOption = null;
    }
    return mappedSearchOption;
  }

  async function searchPo(searchType) {
    console.log('USE EFFECT EXECUTED');
    if (searchType && !searchInput) {
      searchType = null;
      setSearchOption('Transaction Date (YYYY-MM-DD)');
      setQueryFilterStart('');
      setQueryFilterEnd('');
    }
    setLoaderState(true);
    let queryFilterStart;
    let queryFilterEnd;
    const mappedSearchOption = mapSearchOption(searchType);
    const searchInputEnd = searchInput.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
    let q;
    if (mappedSearchOption) {
        setQueryFilterStart(where(mappedSearchOption,'>=',searchInput));
        queryFilterStart = where(mappedSearchOption,'>=',searchInput);
        setQueryFilterEnd(where(mappedSearchOption,'<',searchInputEnd));
        queryFilterEnd = where(mappedSearchOption,'<',searchInputEnd);
        q = query(purchaseOrderRef,
          queryFilterStart,
          queryFilterEnd,
          where("status",'==',status),
          orderBy(mappedSearchOption, "desc"),
          limit(5));
    } else {
        setQueryFilterStart(orderBy("dateTransaction", "desc"));
        queryFilterStart = orderBy("dateTransaction", "desc");
        q = query(purchaseOrderRef,
          queryFilterStart,
          where("status",'==',status),
          limit(5));
    }
    const docsSnapshot = await getDocs(q);
    const tempPurchaseOrderList = [];
    docsSnapshot.forEach(async (doc) => {
      if (doc) {
        tempPurchaseOrderList.push(doc.data());
      }
    });
    // Populate PO list state
    setPurchaseOrders(tempPurchaseOrderList);
    // Get the first visible document
    setFirstVisible(docsSnapshot.docs[0]);
    // Get the last visible document
    setLastVisible(docsSnapshot.docs[docsSnapshot.docs.length-1]);

    setDisablePrevButton(true);
    let nextq;
    if (queryFilterEnd && docsSnapshot.docs.length > 0) {
    nextq = query(purchaseOrderRef,
      queryFilterStart,
      queryFilterEnd,
      where("status",'==',status),
      orderBy(mappedSearchOption, "desc"),
      startAfter(docsSnapshot.docs[docsSnapshot.docs.length-1]),
      limit(5));
    } else if (docsSnapshot.docs.length > 0) {
      nextq = query(purchaseOrderRef,
        queryFilterStart,
        where("status",'==',status),
        startAfter(docsSnapshot.docs[docsSnapshot.docs.length-1]),
        limit(5));
    } else {
      setLoaderState(false);
      setDisableNextButton(true);
      alert(`No Records Found.`);
      return;
    }
    const docsSnapshotNextAhead = await getDocs(nextq);
    if (docsSnapshotNextAhead.docs.length < 1) {
      setDisableNextButton(true);
    } else {
      setDisableNextButton(false);
    }
    setLoaderState(false);
  }

  async function getNext5() {
    // Construct a new query starting at this document,
    setLoaderState(true);
    let nextq;
    if (queryFilterEnd) {
      nextq = query(purchaseOrderRef,
        queryFilterStart,
        queryFilterEnd,
        where("status",'==',status),
        orderBy(mapSearchOption(searchOption), "desc"),
        startAfter(lastVisible),
        limit(5));
      } else {
        nextq = query(purchaseOrderRef,
        queryFilterStart,
        where("status",'==',status),
        startAfter(lastVisible),
        limit(5));
      }
    const docsSnapshot = await getDocs(nextq);
    const tempPurchaseOrderList = [];
    docsSnapshot.forEach(async (doc) => {
      if (doc) {
        tempPurchaseOrderList.push(doc.data());
      }
    });
    // Populate PO list state
    setPurchaseOrders(tempPurchaseOrderList);
    // Get the first visible document
    setFirstVisible(docsSnapshot.docs[0]);
    // Get the last visible document
    setLastVisible(docsSnapshot.docs[docsSnapshot.docs.length-1]);
    let nextnextq;
    if (queryFilterEnd) {
      nextnextq = query(purchaseOrderRef,
        queryFilterStart,
        queryFilterEnd,
        where("status",'==',status),
        orderBy(mapSearchOption(searchOption), "desc"),
        startAfter(docsSnapshot.docs[docsSnapshot.docs.length-1]),
        limit(5));
    } else {
      nextnextq = query(purchaseOrderRef,
        queryFilterStart,
        where("status",'==',status),
        startAfter(docsSnapshot.docs[docsSnapshot.docs.length-1]),
        limit(5));
    }
    const docsSnapshotNextAhead = await getDocs(nextnextq);
    if (docsSnapshotNextAhead.docs.length < 1) {
      setDisableNextButton(true);
    } else {
      setDisableNextButton(false);
    }
    setDisablePrevButton(false);
    setLoaderState(false);
  }

  async function getPrev5() {
    setLoaderState(true);
    // Construct a new query starting at this document,
    let prevq;
    if (queryFilterEnd) {
      prevq = query(purchaseOrderRef,
        queryFilterStart,
        queryFilterEnd,
        where("status",'==',status),
        orderBy(mapSearchOption(searchOption), "desc"),
        endBefore(firstVisible),
        limitToLast(5));
    } else {
      prevq = query(purchaseOrderRef,
        queryFilterStart,
        where("status",'==',status),
        endBefore(firstVisible),
        limitToLast(5));
    }
    const docsSnapshot = await getDocs(prevq);
    const tempPurchaseOrderList = [];
    docsSnapshot.forEach(async (doc) => {
      if (doc) {
        tempPurchaseOrderList.push(doc.data());
      }
    });
    // Populate PO list state
    setPurchaseOrders(tempPurchaseOrderList);
    // Get the first visible document
    setFirstVisible(docsSnapshot.docs[0]);
    // Get the last visible document
    setLastVisible(docsSnapshot.docs[docsSnapshot.docs.length-1]);
    // Check previous of current previous if there are any items to show
    let prevprevq;
    if (queryFilterEnd) {
      prevprevq = query(purchaseOrderRef,
        queryFilterStart,
        queryFilterEnd,
        where("status",'==',status),
        orderBy(mapSearchOption(searchOption), "desc"),
        endBefore(docsSnapshot.docs[0]),
        limitToLast(5));
    } else {
      prevprevq = query(purchaseOrderRef,
        queryFilterStart,
        where("status",'==',status),
        endBefore(docsSnapshot.docs[0]),
        limitToLast(5));
    }
    const docsSnapshotPrevAhead = await getDocs(prevprevq);
    if (docsSnapshotPrevAhead.docs.length < 1) {
        setDisablePrevButton(true);
    }
    setDisableNextButton(false);
    setLoaderState(false);
  }

  function poDetailsLink(poId) {
    navigate(`/podetails/${poId}`);
  }


  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div>
      <div>
        <center>
          <input type='searchInput' value={searchInput} id='searchInput' style={{marginBottom:10}}
            onChange={searchInputListener}
            onKeyDown={enterKeySearch}/>
          &nbsp;
          <Button variant="primary" onClick={() => searchPo(searchOption)}>Search</Button>
          <Form>
          <Form.Group className="mb-3" controlId="inputItem">
          <InputGroup className="mb-1" size="sm" style={{display:"inline-block", marginTop:5}}>
            <DropdownButton
              id="search-options-dropdown-button"
              title={searchOption}
            >
              <Dropdown.Item onClick={() => {searchOptionListener('Transaction Date (YYYY-MM-DD)')}}>Transaction Date (YYYY-MM-DD)</Dropdown.Item>
              <Dropdown.Item onClick={() => {searchOptionListener('Purchase Order Id')}}>Purchase Order Id</Dropdown.Item>
              <Dropdown.Item onClick={() => {searchOptionListener('Supplier Name')}}>Supplier Name</Dropdown.Item>
            </DropdownButton>
            &nbsp;
            <DropdownButton
              id="status-dropdown-button"
              title={status}
            >
            {
              PoStatusList.map((status) => (
                <Dropdown.Item
                  key={status}
                  onClick={() => {statusListener(status)}}
                >
                  {status}
                </Dropdown.Item>
              ))
            }
            </DropdownButton>
          </InputGroup>
          </Form.Group>
          </Form>
        </center>
        <ListGroup>
        { isComponent
          ? purchaseOrders.map((val, index) => (
            <ListGroup.Item key={val.purchaseOrderId} action onClick={() => {onSelect(val.purchaseOrderId)}}>
              <div className="ms-2 me-auto">
                <b>PO:</b> {val.purchaseOrderId}
                &nbsp; <b>Status</b>: {val.status}
                &nbsp; <b>Supplier</b>: {val.supplierName}
              </div>
            </ListGroup.Item>
          ))
          : purchaseOrders.map((val, index) => (
            <ListGroup.Item key={val.purchaseOrderId} action onClick={() => {poDetailsLink(val.purchaseOrderId)}}>
              <div className="ms-2 me-auto">
                <b>PO:</b> {val.purchaseOrderId}
                &nbsp; <b>Status</b>: {val.status}
                &nbsp; <b>Supplier</b>: {val.supplierName}
              </div>
            </ListGroup.Item>
          ))
        }
        </ListGroup>
        <br></br>
        <center>
          { disablePrevButton
            ? null
            : <Button variant="primary" onClick={getPrev5}>Previous</Button>
          }
          &nbsp;&nbsp;
          { disableNextButton
            ? null
            : <Button variant="primary" onClick={getNext5}>Next</Button>
          }
        </center>
      </div>
    </div>
    </CustomLoader>
  );
}

export default PoSearchComponent;
