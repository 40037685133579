import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ListGroup from 'react-bootstrap/ListGroup';
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./JobSearch.module.css";
import { firebaseObserver } from '../../firebaseConfig';
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { JobStatusList, JobTypeList } from "../../models/jobs";
import { listDocumentsWithPagination } from "../../components/database/standard/get";
import { searchDocuments } from "../../components/database/standard/search";


const JobSearch = () => {

  const [jobs, setJobs] = useState([]);
  const [jobStatus, setJobStatus] = useState("ALL");
  const [jobType, setJobType] = useState("ALL");

  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
    });
    searchJob();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function jobStatusListener(jobStatus) {
    setJobStatus(jobStatus);
  }

  function jobTypeListener(jobType) {
    setJobType(jobType);
  }

  function jobDetailsLink(jobId) {
    navigate(`/jobdetails/${jobId}`);
  }

  async function searchJob(status, type) {
    let jobResult;
    let queryFilters = [];
    if ((!status || !type) &&
      (jobStatus === "ALL" && jobType === "ALL")) {
      jobResult = await listDocumentsWithPagination("jobs", 5, "dateModified", "desc");
    } else {
      if (jobStatus !== "ALL") {
        queryFilters.push({key: "status", inputValue: jobStatus})
      };
      if (jobType !== "ALL") {
        queryFilters.push({key: "type", inputValue: jobType})
      };
      jobResult = await searchDocuments("jobs", queryFilters, 5, "dateModified", "desc", true);
    }
    setJobs(jobResult.docs);
  }

  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center><h3 style={{marginTop:15}}>Search Jobs</h3></center>
      <div className={classes.mainInputContainer}>
        <center>
          <Form>
          <Form.Group className="mb-3" controlId="inputItem">
          <InputGroup className="mb-1" size="sm" style={{display:"inline-block", marginTop:5}}>
          <b>Job Status</b>
            &nbsp;
            <DropdownButton
              id="status-dropdown-button"
              title={jobStatus}
            >
            {
              JobStatusList.map((jobStatus) => (
                <Dropdown.Item
                  key={jobStatus}
                  onClick={() => {jobStatusListener(jobStatus)}}
                >
                  {jobStatus}
                </Dropdown.Item>
              ))
            }
            </DropdownButton>
            &nbsp;
            &nbsp;
            <b>Job Type</b>
            &nbsp;
            <DropdownButton
              id="type-dropdown-button"
              title={jobType}
            >
            {
              JobTypeList.map((jobType) => (
                <Dropdown.Item
                  key={jobType}
                  onClick={() => {jobTypeListener(jobType)}}
                >
                  {jobType}
                </Dropdown.Item>
              ))
            }
            </DropdownButton>
          </InputGroup>
          </Form.Group>
          </Form>
          <Button variant="primary" onClick={() => {searchJob(jobStatus, jobType)}}> Search Job</Button>
          <h4>5 Most Recent jobs</h4>
        </center>
        <ListGroup>
        {
          jobs.map((val, index) => (
            <ListGroup.Item key={val.jobId} action onClick={() => {jobDetailsLink(val.jobId)}}>
              <div className="ms-2 me-auto">
                <b>Type</b>: {val.type}
                &nbsp; <b>Status:</b> {val.status}
                <br></br>
                &nbsp; <b>Start</b>: {val.startedAt.toDate().toLocaleString()}
                &nbsp; <b>End</b>: {val.dateModified.toDate().toLocaleString()}
                &nbsp; <b>Triggered By</b>: {val.triggeredBy}
              </div>
            </ListGroup.Item>
          ))
        }
        </ListGroup>
      </div>
    </div>
    </CustomLoader>
  );
}

export default JobSearch;
