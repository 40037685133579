import { db } from '../../../firebaseConfig';
import { getDocs, collection, query, where, orderBy, startAfter, limit } from 'firebase/firestore/lite';

export const searchSupplierDocument = async (searchOption, searchInput, searchLimit) => {
  let q;
  let queryFilterStart;
  let queryFilterEnd;
  let isNextAvailable = false;
  const collectionRef = collection(db, "supplier");

  if (searchInput) {
    const searchInputEnd = searchInput.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
    queryFilterStart = where(searchOption,'>=',searchInput);
    queryFilterEnd = where(searchOption,'<',searchInputEnd);
    if (searchLimit) {
      q = query(collectionRef,
        queryFilterStart,
        queryFilterEnd,
        orderBy(searchOption, "asc"),
        limit(searchLimit));
    } else {
      q = query(collectionRef,
        queryFilterStart,
        queryFilterEnd,
        orderBy(searchOption, "asc"));
    }
  } else {
    queryFilterStart = orderBy(searchOption, "asc");
    if (searchLimit) {
      q = query(collectionRef,
        queryFilterStart,
        limit(searchLimit));
    } else {
      q = query(collectionRef,
        queryFilterStart);
    }
  }
  const docsSnapshot = await getDocs(q);
  const tempList = [];
  docsSnapshot.forEach((doc) => {
    if (doc) {
      tempList.push(doc.data());
    }
  });
  // Populate PO list state
  const parsedDocs = tempList;
  const firstVisibleDocument = docsSnapshot.docs[0];
  const lastVisibleDocument = docsSnapshot.docs[docsSnapshot.docs.length-1];

  if (searchInput && docsSnapshot.docs.length < 1) {
    alert("No Records Found.");
    return ({
      docs: [],
      firstVisibleDocument: null,
      lastVisibleDocument: null,
      isNextAvailable: false
    })
  }

  let nextq;
  if (searchInput) {
    if (searchLimit) {
      nextq = query(collectionRef,
        queryFilterStart,
        queryFilterEnd,
        orderBy(searchOption, "asc"),
        startAfter(lastVisibleDocument),
        limit(searchLimit));
    } else {
      nextq = query(collectionRef,
        queryFilterStart,
        queryFilterEnd,
        orderBy(searchOption, "asc"),
        startAfter(lastVisibleDocument));
    }
  } else {
    if (searchLimit) {
      nextq = query(collectionRef,
        queryFilterStart,
        startAfter(lastVisibleDocument),
        limit(searchLimit));
    } else {
      nextq = query(collectionRef,
        queryFilterStart,
        startAfter(lastVisibleDocument));
    }
  }
  const docsSnapshotNextAhead = await getDocs(nextq);
  if (docsSnapshotNextAhead.docs.length < 1) {
    isNextAvailable = false;
  } else {
    isNextAvailable = true;
  }

  return ({
    docs: parsedDocs,
    firstVisibleDocument: firstVisibleDocument,
    lastVisibleDocument: lastVisibleDocument,
    isNextAvailable: isNextAvailable
  })
};
