import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { insertDocument } from "../database/standard/insert";


const InsertDataButtonModal = (props) => {

  const label = props.label;
  const dbName = props.dbName;
  const elementObjectKey = props.elementObjectKey;
  const elementDescriptionObjectKey = `${props.elementObjectKey}Description`;
  const [element, setElement] = useState('');
  const [elementDescription, setElementDescription] = useState('');

  const [show, setShow] = useState(false);
  const displayName = props.displayName;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function elementListener(event) {
    setElement(event.target.value.toUpperCase());
  }
  function elementDescriptionListener(event) {
    setElementDescription(event.target.value);
  }

  async function createNewElement() {
    if (!validateInputs()) {
      alert(`${label} is required`);
      return;
    }
    const elementData = {
      [elementObjectKey]: element,
      [elementDescriptionObjectKey]: elementDescription
    };
    const insertSuccess = await insertDocument(dbName, elementObjectKey, elementData, false, displayName);
    if (!insertSuccess){
      return;
    }
    setElement(element);
    setShow(false);
  }

  function validateInputs() {
    if (!element) {
      return false;
    }
    return true;
  }


  return (
    <React.Fragment>
      <Button variant="primary" onClick={handleShow}>Create New {label}</Button>
      <Modal
        size="m"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New {label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3" controlId="inputItem">
            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="element">{label}</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder={label}
              value={element}
              onChange={elementListener}
            />
            </InputGroup>
            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="itemTypeDescription">{label} Description</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder={label.concat(" Description")}
              value={elementDescription}
              onChange={elementDescriptionListener}
            />
            </InputGroup>
          </Form.Group>
          </Form>
          <center><Button variant="primary" onClick={createNewElement}>Create</Button></center>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default InsertDataButtonModal

