import React from "react";


const CustomFeedbackValidation = (props) => {
  return (
      props.validated
      ? <React.Fragment>
        { props.evaluatedValue
          ? null
          : <label style={{margin:"0.25rem", fontSize:".875em", color:"#dc3545"}}>
              {props.label} must not be blank.
            </label>
        }
        </React.Fragment>
      : null
  )
}

export default CustomFeedbackValidation;
