import { db } from '../../../firebaseConfig';
import { doc, collection, updateDoc, Timestamp } from 'firebase/firestore/lite';


const purchaseOrderRef = collection(db, "purchase-order");
const purchaseOrderItemsRef = collection(db, "purchase-order-items");

export const updatePurchaseOrderDocument = async (documentId, updateData, contextUser) => {
  updateData.modifiedBy = contextUser;
  updateData.dateModified = Timestamp.now();
  await updateDoc(doc(purchaseOrderRef, documentId), updateData);
  return true;
};

export const updatePurchaseOrderItemsDocument = async (documentId, updateData, contextUser) => {
  updateData.modifiedBy = contextUser;
  updateData.dateModified = Timestamp.now();
  await updateDoc(doc(purchaseOrderItemsRef, documentId), updateData);
  return true;
};
