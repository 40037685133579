import * as React from "react";
import { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./SupplierDetails.module.css";
import { firebaseObserver } from '../../firebaseConfig';
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import { updateSupplierDocument } from "../../components/database/supplier/updateSupplier";
import { getSupplierDocument } from "../../components/database/supplier/getSupplier";


const SupplierDetails = () => {

  const params = useParams();
  const [supplierDocId, setSupplierDocId] = useState('');
  // contactPerson input form listener
  const [supplierName, setSupplierName] = useState(params.supplierName);
  // address input form listener
  const [address, setAddress] = useState('');
  // contactPerson input form listener
  const [contactPerson, setContactPerson] = useState('');
  // cellphoneNumber input form listener
  const [cellphoneNumber, setCellphoneNumber] = useState('');
  // emailAddress input form listener
  const [emailAddress, setEmailAddress] = useState('');
  // modifedBy input form listener
  const [modifiedBy, setModifiedBy] = useState('');
  // dateModified input form listener
  const [dateModified, setDateModified] = useState('');

  // User Auth Context
  const { user } = useAuthContext();
  // User Display Name
  const [displayName, setDisplayName] = useState(user?.displayName);
  // Edit Switch
  const [enableEdit, setEnableEdit] = useState(false);
  // Create user Result
  const [submitSuccess, setSubmitSuccess] = useState(false);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', user => {
      if (!user) {
        alert('Unauthorized access.');
        navigate('/');
      }
      setDisplayName(user.displayName);
    });
    searchSupplier(params.supplierName);
    return () => { firebaseObserver.unsubscribe('authStateChanged'); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[displayName, params.supplierName]);

  // DB call functions
  async function searchSupplier(supplierName) {
    console.log('USE EFFECT EXECUTED');
    const docsSnapshot = await getSupplierDocument(supplierName);
    setSupplierDocId(docsSnapshot.docs[0].id);
    populateFields(docsSnapshot.docs[0].data());
  }
  // end db call functions

  // Input Listeners
  function supplierNameListener(supplier) {
    setSupplierName(supplier);
  }
  function addressListener(event) {
    setAddress(event.target.value);
  }
  function contactPersonListener(event) {
    setContactPerson(event.target.value);
  }
  function cellphoneNumberListener(event) {
    setCellphoneNumber(event.target.value);
  }
  function emailAddressListener(event) {
    setEmailAddress(event.target.value);
  }

  async function editSupplier() {
    const supplierData = {
      supplierName: supplierName,
      address: address,
      contactPerson: contactPerson,
      cellphoneNumber: cellphoneNumber,
      emailAddress: emailAddress,
    };

    setLoaderState(true);
    await updateSupplierDocument(supplierDocId, supplierData, displayName);
    setLoaderState(false);
    setSubmitSuccess(true);
  }

  function toggleEdit() {
    if (enableEdit) {
      alert('You will lose the changes you made. Please click the "Modify Supplier and Save" Button next time if you want to save your changes');
      searchSupplier(params.supplierName);
    }
    setEnableEdit(!enableEdit);
  }

  function populateFields(itemDocument) {
    setSupplierName(itemDocument.supplierName);
    setAddress(itemDocument.address);
    setContactPerson(itemDocument.contactPerson);
    setCellphoneNumber(itemDocument.cellphoneNumber);
    setEmailAddress(itemDocument.emailAddress);
    setModifiedBy(itemDocument.modifiedBy);
    setDateModified(itemDocument.dateModified.toDate());
    setSubmitSuccess(false);
  }

  return (
    <CustomLoader
      isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
      <center style={{margin:15}}>
        <h3 style={{display:"inline"}}>Supplier </h3>
        { enableEdit
          ? <i className='fas fa-lock-open' onClick={toggleEdit}></i>
          : <i className='fas fa-lock' onClick={toggleEdit}></i>
        }
      </center>
        { submitSuccess
          ? <center>
              <div className={classes.mainInputContainer}>
                <h3>Supplier Successfully Edited.</h3>
                Supplier Name: {supplierName}
                <br></br><br></br>
                <Button variant="primary" onClick={() => {window.location.reload(false)}}>View Supplier Details</Button>
                &nbsp;
                <Button variant="primary" onClick={() => {navigate('/suppliercreate')}}>Create Another Supplier</Button>
                &nbsp;
                <Button variant="primary" onClick={() => {navigate('/')}}>Exit</Button>
              </div>
            </center>
          : <div className={classes.mainInputContainer}>
            { enableEdit
              ? <Form>
                <Form.Group className="mb-3" controlId="inputSupplier">
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="supplier-name">Supplier Name</InputGroup.Text>
                  <Form.Control
                    type="text" placeholder="Supplier Name" value={supplierName}
                    onChange={supplierNameListener}
                    disabled={true}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="address">Address</InputGroup.Text>
                  <Form.Control
                    type="text" placeholder="Address" value={address}
                    onChange={addressListener}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="contact-person">Contact Person</InputGroup.Text>
                  <Form.Control
                    type="text" placeholder="Contact Person" value={contactPerson}
                    onChange={contactPersonListener}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="cellphone-number">Cellphone Number</InputGroup.Text>
                  <Form.Control
                    type="text" placeholder="Cellphone Number" value={cellphoneNumber}
                    onChange={cellphoneNumberListener}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="email-address">Email Address</InputGroup.Text>
                  <Form.Control
                    type="text" placeholder="Email Address" value={emailAddress}
                    onChange={emailAddressListener}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="modifiedBy">Modified By</InputGroup.Text>
                  <Form.Control
                    type="text" value={modifiedBy}
                    disabled={true}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="dateModified">Date Modified</InputGroup.Text>
                  <Form.Control
                    type="text" value={dateModified}
                    disabled={true}
                  />
                  </InputGroup>
                </Form.Group>
              </Form>
              : <Form>
                <Form.Group className="mb-3" controlId="inputPurchaseOrder">
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="supplier-name">Supplier Name</InputGroup.Text>
                  <Form.Control
                    type="text" placeholder="Supplier Name" value={supplierName}
                    onChange={supplierNameListener}
                    required={true}
                    disabled={true}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="address">Address</InputGroup.Text>
                  <Form.Control
                    type="text" placeholder="Address" value={address}
                    onChange={addressListener}
                    disabled={true}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="contact-person">Contact Person</InputGroup.Text>
                  <Form.Control
                    type="text" placeholder="Contact Person" value={contactPerson}
                    onChange={contactPersonListener}
                    disabled={true}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="cellphone-number">Cellphone Number</InputGroup.Text>
                  <Form.Control
                    type="text" placeholder="Cellphone Number" value={cellphoneNumber}
                    onChange={cellphoneNumberListener}
                    disabled={true}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="email-address">Email Address</InputGroup.Text>
                  <Form.Control
                    type="text" placeholder="Email Address" value={emailAddress}
                    onChange={emailAddressListener}
                    disabled={true}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="modifiedBy">Modified By</InputGroup.Text>
                  <Form.Control
                    type="text" value={modifiedBy}
                    disabled={true}
                  />
                  </InputGroup>
                  <InputGroup className="mb-1" size="sm">
                  <InputGroup.Text id="dateModified">Date Modified</InputGroup.Text>
                  <Form.Control
                    type="text" value={dateModified}
                    disabled={true}
                  />
                  </InputGroup>
                </Form.Group>
              </Form>
            }
            { enableEdit
              ? <center><Button variant="primary" onClick={editSupplier}>Modify Supplier and Save</Button></center>
              : <center><Button variant="primary" onClick={() => {navigate('/');}}>Exit</Button></center>
            }
          </div>
        }
    </div>
    </CustomLoader>
  );
}

export default SupplierDetails;
