import MainHeader from "../../components/navigation/MainHeader";
import classes from "./PoSearch.module.css";
import PoSearchComponent from "../../components/functional/PoSearchComponent";


const PoSearch = () => {

  return (
    <div className={classes.overlayWrapper}><MainHeader />
      <center><h3 style={{marginTop:15}}>Search Purchase Order</h3></center>
      <div className={classes.mainInputContainer}>
        <center>
          <PoSearchComponent isComponent={false}/>
        </center>
      </div>
    </div>
  );
}

export default PoSearch;
