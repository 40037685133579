import { db } from '../../../firebaseConfig';
import { doc, collection, deleteDoc } from 'firebase/firestore/lite';


const purchaseOrderRef = collection(db, "purchase-order");
const purchaseOrderItemsRef = collection(db, "purchase-order-items");

export const deletePurchaseOrderDocument = async (documentId) => {
  await deleteDoc(doc(purchaseOrderRef, documentId));
  return true;
};

export const deletePurchaseOrderItemsDocument = async (documentId) => {
  await deleteDoc(doc(purchaseOrderItemsRef, documentId));
  return true;
};
